import { mergeMessageLists } from '../message';
import { hasMoreFollowUps } from '../../query/FollowUpThreads.gql';
/**
 * Merge incoming message thread pages with cached ones.
 *
 * Also manage the state of the "has more left to query" local variable by
 * inspecting the latest page of messages.
 */
export const followUpChats = {
    keyArgs: [],
    merge(existing = [], incoming = [], opts) {
        // If the API returned fewer results than we requested, we must be
        // at the end of the pagination.
        if (incoming.length < opts.args?.limit || 0) {
            hasMoreFollowUps(false);
        }
        return mergeMessageLists(existing.slice(), incoming.slice(), opts);
    },
};
