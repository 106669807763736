import React, { useCallback, useMemo, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useParams, useSearchParams } from 'react-router-dom';
import { Select, Alert, Image, Tooltip, Grid, Skeleton, Button, List, Typography, Tag, message, } from 'antd';
import { TranslationOutlined, CheckOutlined, HourglassOutlined, MailOutlined, ExclamationCircleOutlined, QuestionCircleOutlined, EyeInvisibleOutlined, } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useApolloClient, } from '@apollo/client';
import { useClientInfoCtx } from '../ClientInfoProvider';
import { ChatInput } from './ChatInput';
import { ackMessage } from '../net/query/AckMessage.gql';
import { useChat } from '../net/query/Chat.gql';
import { useTranslation, Languages } from '../Translate';
import { MsgStatus, DeviceType } from '../net/types/graphql';
import { MediaMessage } from './Mms';
import { staticPath } from '../util/static';
import { useIsAdmin } from '../state/hooks';
/**
 * Get hover text for the message status icon.
 */
export const getStatusTitle = (msgStatus, code) => {
    switch (msgStatus) {
        case MsgStatus.Delivered:
            return 'Delivered';
        case MsgStatus.Queued:
        case MsgStatus.Accepted:
            return 'Queued';
        case MsgStatus.Sent:
            return 'Sent';
        case MsgStatus.Failed:
            return `Failed: code ${code}`;
        case MsgStatus.NotSent:
            return 'Not sent';
        case MsgStatus.Undelivered:
            return `Undelivered: code ${code}`;
        case MsgStatus.Void:
            return 'Void';
        default:
            return 'Unknown';
    }
};
/**
 * Get the style for the message status icon.
 */
export const getStatusStyle = (msgStatus) => {
    switch (msgStatus) {
        case MsgStatus.Failed:
        case MsgStatus.NotSent:
        case MsgStatus.Undelivered:
            return {
                color: 'var(--nudge-error-color)',
                fontSize: '1rem',
            };
        default:
            return {};
    }
};
/**
 * Get the icon to display to indicate the message status.
 */
export const getStatusIcon = (msgStatus) => {
    switch (msgStatus) {
        case MsgStatus.Delivered:
            return React.createElement(CheckOutlined, null);
        case MsgStatus.Queued:
        case MsgStatus.Accepted:
            return React.createElement(HourglassOutlined, null);
        case MsgStatus.Sent:
            return React.createElement(MailOutlined, null);
        case MsgStatus.Failed:
        case MsgStatus.NotSent:
        case MsgStatus.Undelivered:
            return React.createElement(ExclamationCircleOutlined, null);
        case MsgStatus.Void:
            return React.createElement(EyeInvisibleOutlined, null);
        default:
            return React.createElement(QuestionCircleOutlined, null);
    }
};
/**
 * Descriptions for why the UI might be locked (if it's locked).
 */
const LOCK_REASONS = {
    experiment: (React.createElement(React.Fragment, null,
        React.createElement("p", null,
            "This client is enrolled in an experimental control group to evaluate the effectiveness of our automated messaging. They are ",
            React.createElement("strong", null, "not"),
            ' ',
            "receiving any text messages."),
        React.createElement("p", null,
            "The messages you see here are placeholders that indicate when we",
            ' ',
            React.createElement("em", null, "would have"),
            " sent a message, if the client were not being used as a control. The client ",
            React.createElement("strong", null, "cannot"),
            " see these placeholders."),
        React.createElement("p", null, "Messaging functionality has been disabled for this client while they are in the experiment. Please contact an admin for help if needed."))),
    partialControl: (React.createElement(React.Fragment, null,
        React.createElement("p", null, "Some of the messages to this client have been logged as part of an experimental control treatment."),
        React.createElement("p", null,
            "The faded blue messages in the thread are placeholders for messages that we could have sent, but did not. The client",
            ' ',
            React.createElement("strong", null, "cannot see the faded blue messages"),
            "! They can only see the solid blue messages."),
        React.createElement("p", null, "You are able to send messages to this client as normal. Please contact an admin if you have any questions."))),
    optOut: (React.createElement(React.Fragment, null,
        React.createElement("p", null, "This client has opted out of receiving text messages."),
        React.createElement("p", null, "We cannot send them text messages through Nudge. If you need to get in contact with them, you can try calling them or texting them through your own phone number."))),
    other: (React.createElement(React.Fragment, null,
        React.createElement("p", null, "Messaging functionality has been disabled for this client."),
        React.createElement("p", null, "Please contact an admin for help if needed."))),
};
/**
 * Check if there was a significant delay between two messages.
 *
 * This delay is completely heuristic and is used to render the timestamp
 * in the chat window.
 */
export const checkMessageGap = (a, b) => {
    const diff = dayjs(a.sent).diff(b.sent, 'hour');
    // Check for a one hour gap in messages.
    return Math.abs(diff) >= 1;
};
/**
 * Get a unique name of a file for a media attachment.
 */
export const getFileName = (data, mimeType, i) => {
    const name = `${data.source?.account?.fullName || 'Unknown_Client'}-${data.seq}-${i}`.replace(/[^\w\d-]+/g, '_');
    const ext = mimeType.split('/')[1];
    return `${name}.${ext}`;
};
/**
 * Render text or media message content.
 */
export const renderMessageContent = (data, meta) => {
    if (data.mediaUrls.length) {
        return data.mediaUrls.map((media, i) => (React.createElement(MediaMessage, { key: media.uri, url: media.uri, name: getFileName(data, media.contentType, i), mimeType: media.contentType })));
    }
    if (!data.content) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "-original" }, data.content),
        meta?.translation ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "-translation" }, meta?.translation),
            React.createElement("div", { className: "-translation-attr" },
                React.createElement(Image, { src: staticPath('goog-trans-greyscale-short.svg'), preview: false, width: 75 })))) : null));
};
/**
 * One single message bubble.
 */
export const Message = ({ data, selected, setSelected, suppressScroll, }) => {
    const [doTranslate, requestTranslation] = useState(false);
    const [sourceLang, setSourceLang] = useState(undefined);
    const translation = useTranslation({
        skip: !doTranslate,
        text: data.data.content,
        into: 'en',
        source: sourceLang,
    });
    // Display error from translation.
    useEffect(() => {
        if (translation.error) {
            message.error(`Error translating message: ${translation.error}`);
        }
    }, [translation.error]);
    // Update the source language from Google's auto-detection.
    useEffect(() => {
        if (translation.fromLang) {
            setSourceLang(translation.fromLang);
        }
    }, [translation.fromLang]);
    // When a selected node is rendered, scroll it into view in the center of
    // the screen, using smooth scrolling if possible.
    const ref = useCallback((node) => {
        if (!node || !selected || suppressScroll) {
            return;
        }
        node.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, [selected, suppressScroll]);
    const sourceType = data.data.source?.account?.__typename;
    const incoming = sourceType === 'ClientAccount' ||
        sourceType === 'UnknownAccount' ||
        sourceType === 'AssociateAccount';
    return (React.createElement(React.Fragment, null,
        data.renderTime && (React.createElement(Typography.Text, { type: "secondary", className: "nudge-chat-thread-timestamp" }, dayjs(data.data.sent).format('lll'))),
        data.renderName && data.data.source?.account?.id ? (React.createElement(Typography.Text, { className: `nudge-chat-message-name ${incoming ? '-incoming' : '-outgoing'}` }, data.data.source.account.fullName || 'Unknown')) : null,
        React.createElement(List.Item, { key: data.data.id, title: dayjs(data.data.sent).format('LLLL'), className: `nudge-chat-message-container ${incoming ? '-incoming' : '-outgoing'} ${selected ? '-selected' : ''} ${translation.translating ? '-translating' : ''}
        ${data.data.status === MsgStatus.Void ? '-void' : ''}` },
            React.createElement("div", { ref: selected ? ref : undefined, onClick: (e) => {
                    // Select the current message and dispose the event.
                    setSelected(data.data.id);
                    e.preventDefault();
                    e.stopPropagation();
                }, className: `nudge-chat-message ${incoming ? '-incoming' : '-outgoing'}` },
                data.data.content &&
                    incoming &&
                    translation.canTranslate &&
                    selected ? (React.createElement(Tooltip, { title: "Translate with Google" },
                    React.createElement(Button, { className: "nudge-chat-message-translate", shape: "circle", size: "small", disabled: !translation.canTranslate, onClick: () => requestTranslation(true), icon: React.createElement(TranslationOutlined, { style: { color: 'var(--nudge-info-color)' } }) }))) : translation.translation ? (React.createElement(TranslationOutlined, { className: "nudge-chat-message-translate", style: {
                        color: 'var(--nudge-success-color)',
                        backgroundColor: 'white',
                    } })) : null,
                renderMessageContent(data.data, {
                    translation: translation.translation,
                }),
                translation.translation ? (React.createElement("div", { className: "-translation-source" },
                    React.createElement(Select, { onChange: (v) => setSourceLang(v), value: sourceLang, size: "small" }, Languages.map((lang) => (React.createElement(Select.Option, { key: lang.code, value: lang.code }, lang.name)))))) : null,
                selected ? (React.createElement("div", null, data.data.meta.tags.length
                    ? data.data.meta.tags.map((x) => (React.createElement(Tag, { key: x, color: "blue" }, x)))
                    : null)) : null,
                React.createElement("div", { className: "nudge-chat-message-status", style: getStatusStyle(data.data.status), title: getStatusTitle(data.data.status, data.data.errorCode) }, getStatusIcon(data.data.status))))));
};
/**
 * View for the chat message history.
 */
export const Chat = () => {
    const apolloClient = useApolloClient();
    const { clientInfo } = useClientInfoCtx();
    const isAdmin = useIsAdmin();
    const screens = Grid.useBreakpoint();
    const [suppressScroll, setSuppressScroll] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { accountId } = useParams();
    const selectedMsgId = searchParams.get('m');
    const { data, loading, error, fetchMore } = useChat({
        pollInterval: 60000,
        variables: {
            id: accountId,
            limit: 30,
        },
    });
    // If there is a selected message, make sure that it is loaded. The API
    // wasn't designed for arbitrary querying in the middle of the convo like
    // this, so we just have to load pages until we find it. Obviously this isn't
    // performant. We don't currently expect this to be used super often or the
    // chat histories to be super long, so it's good enough for now.
    useEffect(() => {
        if (!selectedMsgId) {
            return;
        }
        if (!data?.hasMoreMessages || !data?.getMessageHistory) {
            return;
        }
        if (!data.getMessageHistory.find((item) => item.id === selectedMsgId)) {
            fetchMore({
                variables: { offset: data.getMessageHistory[0]?.id, limit: 15 },
            });
        }
    }, [fetchMore, data, selectedMsgId]);
    // NOTE: sorting is expected to happen in the cache, so it's never done
    // during render.
    const messages = (data?.getMessageHistory?.slice() || []).map((m, i, l) => ({
        data: m,
        renderTime: i === 0 || checkMessageGap(l[i - 1], m),
        renderName: i === 0 || l[i - 1].source.account?.id !== m.source.account?.id,
    }));
    // Check if every one of our automated messages is delivered silently. This
    // means that the client is completely in a control group and we have not
    // sent them any actual messages.
    const automatedMessages = messages.filter((m) => /^Automated/.test(m.data.source.account?.fullName || ''));
    const isSilent = automatedMessages.length > 0 &&
        automatedMessages.every((m) => m.data.status === MsgStatus.Void);
    // Check if we are sending some (but not all) automated messages silently.
    // This implies that the client is in some control group, but not every
    // control group.
    const isPartiallySilent = automatedMessages.length > 0 &&
        !isSilent &&
        automatedMessages.some((m) => m.data.status === MsgStatus.Void);
    // Check if client has opted out of text messages so that we can disable the
    // UI and explain the situation to the staff user.
    const mobileDevices = clientInfo?.devices?.filter((d) => d.type === DeviceType.Mobile) || [];
    const isOptOut = mobileDevices.length > 0 && mobileDevices.every((d) => d.optedOut);
    // TODO: show warning if *some* device is opted out, but not every.
    const lockReason = isSilent
        ? 'experiment'
        : isPartiallySilent
            ? 'partialControl'
            : isOptOut
                ? 'optOut'
                : null;
    // Do not lock the chat UI if the user is an admin, or if the client is
    // in the partial control group.
    const lockChatUi = (isSilent && !isAdmin) || (isOptOut && !isAdmin);
    const showLockWarning = !!lockReason;
    if (!loading) {
        if (error) {
            useMemo(() => setTimeout(() => message.error(`Error loading chat thread: ${error.message || error}`), 0), []);
            return React.createElement(React.Fragment, null, "An error occurred :(");
        }
        if (!data?.getMessageHistory) {
            useMemo(() => setTimeout(() => message.error(`Chat data appears to be malformed`), 0), []);
            return React.createElement(React.Fragment, null, "An error occurred :(");
        }
    }
    useEffect(() => {
        if (loading || error) {
            return;
        }
        for (const msg of messages) {
            ackMessage(apolloClient, msg.data);
        }
    }, [loading, error, messages]);
    return (React.createElement(React.Fragment, null,
        showLockWarning ? (React.createElement(Alert, { banner: true, type: "warning", message: LOCK_REASONS[lockReason] })) : null,
        React.createElement("div", { id: "nudge-chat-window", className: !screens.md ? '-smol' : '', onClick: () => {
                // Reset the selected message if there is one.
                if (searchParams.get('m')) {
                    setSearchParams({ m: '' });
                }
            } },
            React.createElement(InfiniteScroll, { scrollableTarget: "nudge-chat-window", style: { overflow: 'visible' }, dataLength: messages.length, next: () => {
                    // Suppress scrolling behavior when scrolling away from the selected
                    // message so that scroll doesn't jump.
                    if (selectedMsgId) {
                        setSuppressScroll(true);
                    }
                    fetchMore({
                        variables: { offset: messages[0]?.data?.id, limit: 15 },
                    });
                }, hasMore: data ? data.hasMoreMessages : true, loader: messages.length > 0 &&
                    loading && React.createElement(Skeleton, { paragraph: { rows: 1 }, active: true }), inverse: true },
                React.createElement(List, { loading: loading, dataSource: messages, renderItem: (item) => (React.createElement(Message, { clientId: accountId, data: item, selected: selectedMsgId === item.data.id, suppressScroll: suppressScroll, setSelected: (id) => {
                            // Make sure scrolling is not suppressed, if it was before.
                            setSuppressScroll(false);
                            setSearchParams({ m: id });
                        } })) }))),
        React.createElement("div", { id: "nudge-compose-box" },
            React.createElement(ChatInput, { disable: lockChatUi }))));
};
