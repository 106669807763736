import { useState, useRef, useEffect } from 'react';
/**
 * Get the width and height of a container.
 */
export const useContainerSize = () => {
    const containerRef = useRef(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    useEffect(() => {
        if (!containerRef.current) {
            return;
        }
        const resize = () => {
            if (!containerRef.current) {
                return;
            }
            const bbox = containerRef.current.getBoundingClientRect();
            setWidth(bbox.width);
            setHeight(bbox.height);
        };
        resize();
        window.addEventListener('resize', resize);
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, [containerRef]);
    return {
        ref: containerRef,
        width,
        height,
    };
};
