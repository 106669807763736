import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Alert, Button, List, Tag, Tooltip, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { UserSwitchOutlined, StopOutlined, MedicineBoxOutlined, ExclamationCircleOutlined, ClearOutlined, BankOutlined, NumberOutlined, } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useSafeNavigate as useNavigate } from '../util/safeNavigate';
import { useNonStandardReplies } from '../net/query/NonStandardReplies.gql';
import { DatePicker } from '../DatePicker';
/**
 * Make sure that the given date range is valid.
 */
const valiDate = (start, end) => {
    if (!start.isValid()) {
        return 'Start date is invalid';
    }
    else if (!end.isValid()) {
        return 'End date is invalid';
    }
    else if (!start.isBefore(end)) {
        return 'Start date must come before end date';
    }
    return null;
};
/**
 * Format a tag component for a message tag.
 *
 * Some of the tags can have special icons and colors to make them a little
 * more comprehensible.
 */
const formatTag = (tag) => {
    const [label, originalValue] = tag.split(':', 2);
    let value = originalValue;
    let icon;
    let color;
    switch (label) {
        case 'wrong_number':
            icon = React.createElement(UserSwitchOutlined, null);
            color = 'orange';
            value = 'wrong number';
            break;
        case 'stop':
            icon = React.createElement(StopOutlined, null);
            color = 'volcano';
            value = 'opt-out';
            break;
        case 'sick':
            icon = React.createElement(MedicineBoxOutlined, null);
            color = 'seagreen';
            value = 'sick';
            break;
        case 'error':
            icon = React.createElement(ExclamationCircleOutlined, null);
            color = 'var(--ant-error-color)';
            value = 'error';
            break;
        case 'court_date':
            icon = React.createElement(BankOutlined, null);
            value = dayjs(value).format('lll');
            color = 'gold';
            break;
        case 'case_number':
            icon = React.createElement(NumberOutlined, null);
            color = 'geekblue';
            break;
        default:
            value = tag;
            break;
    }
    return (React.createElement(Tag, { key: tag, icon: icon, color: color }, value));
};
/**
 * UI for the list of non-standard replies in the given date range.
 */
export const NonStandardMessageList = ({ startDate, endDate, }) => {
    const { data, loading, error } = useNonStandardReplies({
        fetchPolicy: 'cache-and-network',
        variables: {
            from: startDate.toJSON(),
            to: endDate.toJSON(),
        },
    });
    const navigate = useNavigate();
    if (error) {
        return React.createElement(Alert, { type: "error", message: error.message, showIcon: true });
    }
    // Sort messages in reverse chronological order, so most recent are first.
    const msgs = data?.nonStandardReplies?.slice() || [];
    msgs.sort((a, b) => (a.seq > b.seq ? -1 : 1));
    return (React.createElement(List, { className: "nudge-audit-content", loading: loading, dataSource: msgs, renderItem: (item) => (React.createElement(List.Item, { className: `nudge-audit-message-item ${item.source.account?.id ? '-click' : ''}`, onClick: item.source.account?.id
                ? () => navigate(`/chat/${item.source.account.id}?m=${item.id}`)
                : undefined, key: item.id, extra: item.meta.tags.map((t) => formatTag(t)) },
            React.createElement(List.Item.Meta, { avatar: React.createElement(Typography.Text, { type: "secondary", title: dayjs(item.sent).format('LLLL') }, dayjs(item.sent).fromNow()), title: item.source.account?.fullName || 'Unknown sender', description: item.content }))) }));
};
/**
 * View for responses to automated messages that we didn't understand.
 */
export const NonStandardReplies = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    // Update search params with the newly selected date range.
    const updateDateRange = (range) => {
        const [newFrom, newTo] = range || [null, null];
        setSearchParams({
            from: newFrom?.format('YYYY-MM-DD') || '',
            to: newTo?.format('YYYY-MM-DD') || '',
        });
    };
    // NOTE: when filling in defaults, clamp the bounds to the start and end of
    // the day. This does two things:
    //   1) Prevents an infinite refresh from the milliseconds of the timestamp
    //      changing when the query status updates; and
    //   2) Provides a more intuitive definition of "yesterday" which is more
    //      than strictly "the last 24 hours."
    //
    // If the date range is not specified at all, we will use "today" as the
    // anchor point and fetch recent messages. If it's partially specified, we
    // will anchor to the part of the range that is specified.
    const today = dayjs();
    let startDate = today.subtract(1, 'day');
    let endDate = today;
    const hasCustomStart = !!searchParams.get('from');
    const hasCustomEnd = !!searchParams.get('to');
    if (hasCustomStart && hasCustomEnd) {
        startDate = dayjs(searchParams.get('from'));
        endDate = dayjs(searchParams.get('to'));
    }
    else if (hasCustomStart) {
        startDate = dayjs(searchParams.get('from'));
        endDate = startDate.add(1, 'day');
    }
    else if (hasCustomEnd) {
        endDate = dayjs(searchParams.get('to'));
        startDate = endDate.subtract(1, 'day');
    }
    startDate = startDate.startOf('day');
    endDate = endDate.endOf('day');
    // Make sure that the date range actually makes sense now.
    const dateError = valiDate(startDate, endDate);
    return (React.createElement("div", { id: "nudge-nonstandard-replies" },
        React.createElement(PageHeader, { title: "Non-standard responses", backIcon: false, extra: [
                React.createElement(DatePicker.RangePicker, { onChange: updateDateRange, value: [startDate, endDate] }),
                React.createElement(Tooltip, { title: "Reset the date range to the past day." },
                    React.createElement(Button, { type: "link", icon: React.createElement(ClearOutlined, null), onClick: () => updateDateRange(null), disabled: !hasCustomStart && !hasCustomEnd })),
            ] }),
        dateError ? (React.createElement(Alert, { type: "error", message: dateError, showIcon: true })) : null,
        dateError ? null : (React.createElement(NonStandardMessageList, { startDate: startDate, endDate: endDate }))));
};
