import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as log from '../util/log';
const DEFAULT_URL = '/graphql';
const DEFAULT_ROOT = '/';
const cleanUrl = (url, protocol = '') => {
    if (!url.endsWith('/')) {
        url += '/';
    }
    return protocol + url;
};
/**
 * Generate a set of candidate URLs to find the Nudge API.
 */
const nudgeUrlCandidates = (value) => {
    if (/^(https?:\/\/|\/)/.test(value)) {
        return [cleanUrl(value)];
    }
    if (value.startsWith('/')) {
        return [cleanUrl(value)];
    }
    return ['https://', 'http://'].map((protocol) => cleanUrl(value, protocol));
};
/**
 * Set the Nudge base URL to the given value.
 *
 * This async action standardizes the URL and validates that it points to a
 * real Nudge API deployment.
 */
export const updateBaseUrl = createAsyncThunk('baseUrl/updateBaseUrl', async (value) => {
    const cleanUrls = nudgeUrlCandidates(value);
    for (const url of cleanUrls) {
        const result = await fetch(`${url}u_up`);
        if (!result.ok || result.status !== 200) {
            continue;
        }
        const text = await result.text();
        if (text !== 'yo') {
            continue;
        }
        return {
            root: url,
            url: `${url}graphql`,
            valid: true,
        };
    }
    return {
        root: value,
        url: value,
        valid: false,
    };
});
export const baseUrlSlice = createSlice({
    name: 'baseUrl',
    initialState: {
        value: DEFAULT_URL,
        root: DEFAULT_ROOT,
        validated: false,
        validating: false,
        error: null,
    },
    reducers: {
        reset: (state) => {
            state.value = '';
            state.root = DEFAULT_ROOT;
            state.validated = false;
            state.validating = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateBaseUrl.pending, (state, action) => {
            state.validated = false;
            state.validating = true;
            state.value = action.meta.arg;
            state.root = action.meta.arg;
            state.error = null;
        });
        builder.addCase(updateBaseUrl.fulfilled, (state, action) => {
            state.root = action.payload.root;
            state.value = action.payload.url;
            state.validated = action.payload.valid;
            state.validating = false;
            state.error = null;
        });
        builder.addCase(updateBaseUrl.rejected, (state, action) => {
            log.error('Failed to validate Nudge URL:', action.error);
            state.value = action.meta.arg;
            state.root = action.meta.arg;
            state.validated = false;
            state.validating = false;
            state.error = action.error?.message || 'unknown error';
        });
    },
});
export const { reset } = baseUrlSlice.actions;
export const { reducer } = baseUrlSlice;
