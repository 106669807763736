import { gql, useQuery } from '@apollo/client';
import { RIDE_DATA } from './fragments/RideData.gql';
/**
 * Query to fetch information about a client.
 */
export const CLIENT_INFO = gql `
  ${RIDE_DATA}

  query ClientInfo($id: ID!, $idAsStr: String!, $admin: Boolean!) {
    fsm @include(if: $admin) {
      meta {
        nestedStates
      }
    }

    featureAssignments(entityType: "ClientEntity", entityId: $idAsStr)
      @include(if: $admin) {
      featureName
      variant
      value
    }

    node(id: $id) {
      ... on ClientAccount {
        id
        fullName
        isActive
        language
        gender
        externalAppLink
        twilioNumber
        fsmState
        courtDates {
          courtDate
          courtRoom
          courtLocation
          caseNumber
          topCharge
          notes
          online
          confirmedDate
        }
        rides {
          ...RideData
        }
        email
        address
        devices {
          id
          type
          address
          optedOut
        }
        assignedStaff {
          id
          fullName
          roles
        }
      }
    }
  }
`;
/**
 * Hook to fetch info about client.
 */
export const useClientInfo = (opts) => useQuery(CLIENT_INFO, opts);
