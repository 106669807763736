import { configureStore } from '@reduxjs/toolkit';
import { logger } from 'redux-logger';
import { load, save } from 'redux-localstorage-simple';
import { reducer as baseUrl } from './baseUrl';
import { reducer as auth } from './auth';
import { reducer as push, sw } from './push';
import * as log from '../util/log';
/**
 * Namespace in local storage where Nudge state is persisted.
 */
const NUDGE_NS = 'scpl:nudge';
/**
 * Middleware to sync access token with service worker.
 * TODO: we can move all access token stuff to the service worker and then
 * we wouldn't have to do this, plus the requests would be more secure.
 */
const syncState = ({ getState }) => (next) => (action) => {
    const result = next(action);
    const state = getState();
    const { tokenType, token } = state.auth;
    const t = tokenType && token ? `${tokenType} ${token}` : '';
    sw.updateAccessToken(t)
        .then(() => log.debug('Updated service worker access token'))
        .catch((e) => log.error('Failed to update service worker access token', e));
    return result;
};
/**
 * Middleware to persist state.
 */
const saveState = save({ namespace: NUDGE_NS });
/**
 * Application state (Redux store).
 */
export const store = configureStore({
    preloadedState: load({ namespace: NUDGE_NS }),
    middleware: (getDefaultMiddleware) => 
    // NB: Use `.concat` to add more middleware instead of the spread operator.
    // Typescript will lose type information with the spread operator, which
    // will cause the typed version of `AppDispatch` below to be incorrect.
    getDefaultMiddleware()
        .concat(logger)
        .concat(saveState)
        .concat(syncState),
    reducer: {
        baseUrl,
        auth,
        push,
    },
});
