import * as Sentry from '@sentry/react';
// Convert internal levels to Sentry levels.
const levelToSeverity = (level) => {
    switch (level) {
        case 'error':
            return Sentry.Severity.Error;
        case 'warn':
            return Sentry.Severity.Warning;
        case 'info':
            return Sentry.Severity.Info;
        case 'debug':
            return Sentry.Severity.Debug;
        case 'critical':
            return Sentry.Severity.Critical;
        default:
            return Sentry.Severity.Info;
    }
};
/**
 * Get console method from internal log level.
 */
const levelToConsole = (level) => {
    switch (level) {
        case 'error':
            return 'error';
        case 'warn':
            return 'warn';
        case 'debug':
            return 'debug';
        case 'info':
            return 'info';
        case 'critical':
            return 'error';
    }
};
/**
 * Report an event to Sentry.
 */
export const report = (level, msg, err) => {
    const hasError = !!err;
    if (!(err instanceof Error)) {
        if (!err) {
            err = new Error(msg);
        }
        else if (typeof err === 'string') {
            err = new Error(err);
        }
        else {
            // SerializedError
            const se = err;
            err = new Error(se.message);
            err.stack = se.stack;
            err.name = se.name;
        }
    }
    if (__DEV__) {
        if (hasError) {
            console[levelToConsole(level)](msg, err);
        }
        else {
            console[levelToConsole(level)](msg);
        }
    }
    else if (level !== 'error' && level !== 'critical') {
        // Don't bother reporting anything less than an error.
        return;
    }
    Sentry.captureException(err, {
        level: levelToSeverity(level),
        extra: {
            msg,
        },
    });
};
export const error = report.bind(null, 'error');
export const warn = report.bind(null, 'warn');
export const info = report.bind(null, 'info');
export const debug = report.bind(null, 'debug');
export const critical = report.bind(null, 'critical');
