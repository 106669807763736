import { RIDE_DATA } from '../../query/fragments/RideData.gql';
/**
 * Update the cache after successfully scheduling a ride.
 */
export const updateOnScheduleRide = (cache, { data }) => {
    if (!data?.scheduleRide) {
        return;
    }
    const ride = data.scheduleRide;
    // Get cache ID, something like "ClientAccount:abc123xyz987"
    const cacheId = `${ride.client.__typename}:${ride.client.id}`;
    // Update the client info with the new ride.
    cache.modify({
        id: cacheId,
        fields: {
            rides(existing = [], { readField }) {
                const rideCacheId = `${ride.__typename}:${ride.id}`;
                const newRef = cache.writeFragment({
                    id: rideCacheId,
                    fragment: RIDE_DATA,
                    data: ride,
                });
                // Make sure that cache update is idempotent.
                for (const r of existing) {
                    if (readField('id', r) === rideCacheId) {
                        return existing;
                    }
                }
                return [...existing, newRef];
            },
        },
    });
};
