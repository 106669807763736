import { gql, useQuery } from '@apollo/client';
/**
 * Query to fetch details about a ride.
 */
export const RIDE_DETAILS = gql `
  query RideDetails($id: ID!) {
    node(id: $id) {
      id
      ... on Ride {
        lyftDetails {
          rideId
          status
          canCancel
          canceledBy
          origin {
            lat
            lng
            address
          }
          destination {
            lat
            lng
            address
          }
          passenger {
            firstName
            lastName
            imageUrl
            userId
          }
          timezone
          price {
            amount
            currency
          }
          pricingDetailsUrl
          rating
          feedback
          routeUrl
          requestedAt
          generatedAt
          distanceMiles
        }
      }
    }
  }
`;
/**
 * Hook to fetch info about a single ride.
 */
export const useRideDetails = (opts) => useQuery(RIDE_DETAILS, opts);
