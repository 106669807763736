import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as log from '../util/log';
import { urlSafeBase64Encode } from '../util/base64';
import { sha256 } from 'js-sha256';
const DEFAULT_TOKEN = '';
const DEFAULT_TOKEN_TYPE = 'Bearer';
/**
 * Get timestamp in milliseconds at which access token will expire.
 */
const getExpirationTimestamp = (expiresIn, refreshToken) => {
    // Calculate the expiration time for the token.
    const now = Date.now();
    let expiresAt = refreshToken
        ? now + 1000 * 60 * 60 // If there's a refresh token, default to 1hr
        : now + 1000 * 60 * 60 * 24 * 365 * 10; // Otherwise default to 10yrs
    // Read the explicit expiration if it was given
    if (expiresIn) {
        const expiration = parseInt(expiresIn, 10);
        if (!Number.isNaN(expiration)) {
            expiresAt = now + 1000 * expiration;
        }
    }
    return expiresAt;
};
/**
 * Get an access token from the authorization code using PKCE.
 */
export const exchangeCodeForToken = createAsyncThunk('auth/exchange', async ({ code, state, codeVerifier }) => {
    const result = await fetch(`/oauth2/code`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            code,
            clientType: 'web',
            state,
            codeVerifier,
        }),
    });
    if (result.status !== 200) {
        // Note: there might be more info in the response body we could surface
        // to the user if it's useful.
        throw new Error(`Failed to log you in: ${result.statusText}`);
    }
    const data = await result.json();
    if (!data?.accessToken) {
        throw new Error('Failed to get access token from code exchange');
    }
    return {
        expiresAt: getExpirationTimestamp(data.expiresIn, data.refreshToken),
        refreshToken: data.refreshToken,
        token: data.accessToken,
        tokenType: data.tokenType,
    };
});
/**
 * Refresh an access token.
 */
export const refreshAccessToken = createAsyncThunk('auth/refresh', async (refreshRequest) => {
    if (!refreshRequest.refreshToken) {
        throw new Error('No refresh token');
    }
    const result = await fetch('/oauth2/refresh', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            clientType: 'web',
            refreshToken: refreshRequest.refreshToken,
            deviceId: refreshRequest.deviceId,
        }),
    });
    if (result.status !== 200) {
        // Note: there might be more info in the response body we could surface
        // to the user if it's useful.
        throw new Error(`Failed to refresh your access token: ${result.statusText}`);
    }
    const data = await result.json();
    if (!data?.accessToken) {
        throw new Error('Failed to refresh your access token');
    }
    return {
        expiresAt: getExpirationTimestamp(data.expiresIn, data.refreshToken),
        refreshToken: data.refreshToken,
        token: data.accessToken,
        tokenType: data.tokenType,
    };
});
/**
 * All the characters eligible for the code verifier, per:
 * https://www.oauth.com/oauth2-servers/pkce/authorization-request/
 */
const VERIFIER_CHARSET = [
    'abcdefghijklmnopqrstuvwxyz',
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    '1234567890',
    '-._~',
]
    .join('')
    .split('');
/**
 * Get a random character that can be used in the codeVerifier.
 */
const getRandomVerifierChar = () => {
    const x = crypto.getRandomValues(new Uint8Array(1))[0];
    // Get a random character using rejection sampling:
    // https://stackoverflow.com/a/18230432
    if (x >= VERIFIER_CHARSET.length) {
        return getRandomVerifierChar();
    }
    return VERIFIER_CHARSET[x];
};
/**
 * Get a code verifier with `n` random bytes (default 64)
 */
export const getCodeVerifier = (n = 64) => {
    // The code verifier should be no less than 42 bytes and no more than 128.
    if (n < 43) {
        throw new Error("Can't use fewer than 43 bytes");
    }
    if (n > 128) {
        throw new Error("Can't use more than 128 bytes");
    }
    let verifier = '';
    for (let i = 0; i < n; i++) {
        verifier += getRandomVerifierChar();
    }
    return verifier;
};
/**
 * Get the code challenge from the codeVerifier in the manner described here:
 * https://www.oauth.com/oauth2-servers/pkce/authorization-request/
 */
export const getCodeChallenge = async (codeVerifier) => {
    // NOTE: The SubtleCrypto sha256 method is not available in all environments,
    // so we use the pure-JS implementation instead.
    const hash = sha256.create();
    hash.update(codeVerifier);
    const hashBytes = String.fromCharCode(...new Uint8Array(hash.arrayBuffer()));
    return urlSafeBase64Encode(hashBytes);
};
export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        code: '',
        codeVerifier: getCodeVerifier(),
        loggedIn: false,
        userId: null,
        refreshToken: null,
        token: DEFAULT_TOKEN,
        tokenType: DEFAULT_TOKEN_TYPE,
        expiresAt: 0,
        error: null,
        refreshing: false,
        roles: [],
    },
    reducers: {
        updateToken: (state, action) => {
            state.token = action.payload;
        },
        updateLogin: (state, action) => {
            state.loggedIn = true;
            state.userId = action.payload.id;
            state.roles = action.payload.roles.slice();
        },
        reset: (state) => {
            state.code = '';
            state.token = DEFAULT_TOKEN;
            state.tokenType = DEFAULT_TOKEN_TYPE;
            state.refreshing = false;
            state.refreshToken = null;
            state.expiresAt = 0;
            state.error = null;
            state.loggedIn = false;
            state.userId = null;
            state.roles = [];
        },
    },
    extraReducers: (builder) => {
        // Authorization Code Exchange PKE Flow
        builder.addCase(exchangeCodeForToken.pending, (state, action) => {
            state.code = action.meta.arg.code;
            state.refreshing = true;
            state.error = null;
            state.token = DEFAULT_TOKEN;
            state.loggedIn = false;
            state.refreshToken = null;
            state.expiresAt = 0;
        });
        builder.addCase(exchangeCodeForToken.fulfilled, (state, action) => {
            state.code = action.meta.arg.code;
            state.refreshing = false;
            state.error = null;
            state.token = action.payload.token;
            state.tokenType = action.payload.tokenType;
            state.loggedIn = true;
            state.refreshToken = action.payload.refreshToken || null;
            state.expiresAt = action.payload.expiresAt;
        });
        builder.addCase(exchangeCodeForToken.rejected, (state, action) => {
            log.error('Error exchanging token', action.error);
            state.code = action.meta.arg.code;
            state.token = DEFAULT_TOKEN;
            state.tokenType = DEFAULT_TOKEN_TYPE;
            state.error = action.error?.message || 'unknown error';
            state.refreshing = false;
            state.loggedIn = false;
            state.userId = null;
            state.refreshToken = null;
            state.expiresAt = 0;
        });
        // Access Token Refresh Flow
        builder.addCase(refreshAccessToken.pending, (state, action) => {
            state.refreshToken = action.meta.arg.refreshToken || null;
            state.refreshing = true;
            state.error = null;
        });
        builder.addCase(refreshAccessToken.fulfilled, (state, action) => {
            state.refreshing = false;
            state.error = null;
            state.token = action.payload.token;
            state.tokenType = action.payload.tokenType;
            state.loggedIn = true;
            state.refreshToken =
                action.payload.refreshToken || action.meta.arg || null;
            state.expiresAt = action.payload.expiresAt;
        });
        builder.addCase(refreshAccessToken.rejected, (state, action) => {
            log.error('Error refreshing access token', action.error);
            state.refreshing = false;
            state.error = action.error?.message || 'unknown error';
            state.refreshToken = null;
            state.token = DEFAULT_TOKEN;
            state.tokenType = DEFAULT_TOKEN_TYPE;
            state.expiresAt = 0;
            state.loggedIn = false;
            state.userId = null;
        });
    },
});
export const { updateToken, updateLogin, reset } = authSlice.actions;
export const { reducer } = authSlice;
