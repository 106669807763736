/**
 * Merge for the message destination node.
 */
export const destination = {
    keyArgs: (a) => a?.account?.__ref,
    merge: true,
};
/**
 * Merge for the message source node.
 */
export const source = {
    keyArgs: (a) => a?.account?.__ref,
    merge: true,
};
