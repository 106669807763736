import React from 'react';
import { Table, Switch, Button } from 'antd';
import { CheckCircleOutlined, AppleOutlined, AndroidOutlined, PhoneOutlined, LaptopOutlined, RobotOutlined, QuestionCircleOutlined, DeleteOutlined, } from '@ant-design/icons';
import { useAppSelector } from '../state/hooks';
import { useBrowserId } from '../BrowserId';
import { useMyDevices } from '../net/query/MyDevices.gql';
import { DeviceType } from '../net/types/graphql';
import { useToggleUnknownMsgSub } from '../net/query/ToggleUnknownMsgSub.gql';
import { useUnregisterDevice } from '../net/query/UnregisterDevice.gql';
const getDeviceIcon = (t) => {
    switch (t) {
        case DeviceType.Ios:
            return React.createElement(AppleOutlined, null);
        case DeviceType.Android:
            return React.createElement(AndroidOutlined, null);
        case DeviceType.Mobile:
            return React.createElement(PhoneOutlined, null);
        case DeviceType.Web:
            return React.createElement(LaptopOutlined, null);
        case DeviceType.Rpc:
            return React.createElement(RobotOutlined, null);
        default:
            return React.createElement(QuestionCircleOutlined, null);
    }
};
/**
 * Device list and options.
 */
export const ManageDevices = () => {
    const thisDeviceId = useBrowserId();
    const [toggleUnk] = useToggleUnknownMsgSub();
    const [unregister] = useUnregisterDevice();
    const userId = useAppSelector(({ auth }) => auth.userId);
    const { data, loading, error } = useMyDevices({
        variables: {
            id: userId,
        },
    });
    if (error) {
        return React.createElement("div", null,
            "Error: ",
            error.message);
    }
    const rows = ((data?.node?.__typename === 'StaffAccount' && data.node.devices) ||
        []).slice();
    rows.sort((a, b) => a.id === thisDeviceId ? -1 : b.id === thisDeviceId ? 1 : 0);
    return (React.createElement(Table, { rowKey: (x) => x.address, rowClassName: (device) => device.id === thisDeviceId ? '-active-device' : '', loading: loading, dataSource: rows, pagination: false, columns: [
            {
                title: 'Current',
                key: 'current',
                render: (_, device) => device.id === thisDeviceId ? React.createElement(CheckCircleOutlined, null) : null,
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                render: (_, device) => getDeviceIcon(device.type),
            },
            {
                title: 'Device ID',
                dataIndex: 'address',
                key: 'address',
            },
            {
                title: 'Subscribe to notifications from everyone',
                dataIndex: 'receiveUnknownMessages',
                key: 'receiveUnknownMessages',
                render: (_, device) => (React.createElement(Switch, { checked: device.receiveUnknownMessages || false, onClick: (checked) => toggleUnk({
                        variables: {
                            deviceId: device.id,
                            toggle: checked,
                        },
                    }) })),
            },
            {
                title: 'Delete',
                key: 'delete',
                render: (_, device) => thisDeviceId === device.id ? null : (React.createElement(Button, { icon: React.createElement(DeleteOutlined, null), type: "link", danger: true, onClick: () => unregister({ variables: { deviceId: device.id } }) })),
            },
        ] }));
};
