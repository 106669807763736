import { gql, useMutation } from '@apollo/client';
import { updateOnUnregisterDevice } from '../cache/update/unregisterDevice';
/**
 * Mutation to unregister this web client with the server.
 */
export const UNREGISTER_DEVICE = gql `
  mutation UnregisterDevice($deviceId: ID!) {
    unregisterDevice(id: $deviceId) {
      id
    }
  }
`;
/**
 * Hook to unregister a Nudge device with the server.
 */
export const useUnregisterDevice = (opts) => {
    return useMutation(UNREGISTER_DEVICE, {
        update: updateOnUnregisterDevice,
        ...opts,
    });
};
