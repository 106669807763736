import dayjs from 'dayjs';
import { RideStatus } from '../net/types/graphql';
/**
 * Match a ride that's scheduled for a given court date.
 */
export const findRideForCourtDate = (courtDate, rides, direction) => {
    const cd = dayjs(courtDate.courtDate);
    return rides.find((r) => {
        if (r.status === RideStatus.Canceled) {
            return false;
        }
        // Match the ride to the court date if it's recorded under the same case #.
        if (r.caseNumber !== courtDate.caseNumber) {
            return false;
        }
        const st = dayjs(r.scheduledTime);
        if (!st.isValid()) {
            return false;
        }
        // Match the ride to the court date if it's scheduled for the same day
        // and is before the court date starts.
        if (st.isSame(cd, 'day')) {
            return direction === 'to' ? st.isBefore(cd) : !st.isBefore(cd);
        }
        return false;
    });
};
