import React, { useContext, createContext } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { LoadingContainer } from '../LoadingContainer';
import { useAppConfig } from '../net/query/AppConfig.gql';
/**
 * Context to store the GoogleMaps JS API.
 */
const GoogleMapsApiContext = createContext(null);
/**
 * Get a reference to the GoogleMaps JS API (normally accessed via window).
 */
export const useGoogleMapsApi = () => {
    const gmaps = useContext(GoogleMapsApiContext);
    if (!gmaps) {
        throw new Error('Failed to get a reference to the GoogleMaps API');
    }
    return gmaps;
};
const _GMAPInternal = ({ children, apiKey }) => {
    // Load the GoogleMaps JS API via a Script tag, then put it in the context.
    const { isLoaded, loadError } = useJsApiLoader({
        id: 'gmaps-js-api',
        googleMapsApiKey: apiKey,
    });
    const error = loadError ||
        (isLoaded && !window.google?.maps
            ? new Error('Failed to load Google Maps JS API')
            : null);
    return (React.createElement(LoadingContainer, { style: { height: 'auto' }, error: error, loading: !isLoaded },
        React.createElement(GoogleMapsApiContext.Provider, { value: window.google?.maps || null }, children)));
};
/**
 * Supply Google Maps JS API via context.
 */
export const GoogleMapsApiProvider = ({ children, }) => {
    const { data: appConfig, loading, error } = useAppConfig();
    return (React.createElement(LoadingContainer, { style: { height: 'auto' }, error: error, loading: loading },
        React.createElement(_GMAPInternal, { apiKey: appConfig?.clientConfig?.googleMapsKey || '' }, children)));
};
