import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { SentryConfig } from './src/util/sentry';
import { isDebugging } from './src/util/env';
import { Root } from './src/Root';
// Load debug tools if necessary
if (isDebugging()) {
    import('./src/util/debug');
}
// Install plugins for DayJS
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
// Configure error reporting
Sentry.init({
    ...SentryConfig,
    integrations: [new Integrations.BrowserTracing()],
    initialScope: {
        tags: { mode: 'foreground' },
    },
});
// Create a root element in the HTML template if it doesn't exist.
if (!document.querySelector('#root')) {
    const root = document.createElement('div');
    root.id = 'root';
    document.querySelector('body').appendChild(root);
}
// Render the React App into the root.
const rootEl = document.getElementById('root');
const root = createRoot(rootEl);
// Render the document.
root.render(React.createElement(Root, null));
