import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { App } from './App';
import { RegisteredClient } from './RegisteredClient';
import { store } from './state/store';
import { NetworkProvider } from './NetworkProvider';
import { ThemeProvider } from './ThemeProvider';
import { Chat, ChatWindow } from './Chat';
import { AuditWindow, NonStandardReplies, FollowUp } from './Audit';
import { Login } from './Login';
import { Setup } from './Setup';
import { OAuth2Callback } from './OAuth2Callback';
import { BrowserId } from './BrowserId';
import { LogoutSplash } from './Logout';
import { ViewRides } from './Transit/ViewRides';
import { RequestRide } from './Transit/RequestRide';
import { ManageRide } from './Transit/ManageRide';
import { Settings } from './Settings';
import { Help } from './Help';
/**
 * The root component supplies all the Providers and Routes to run the app.
 *
 * Try to keep all the core App features (as opposed to the services providers)
 * inside of the Route `element` props. That way it's easier to mock these
 * service dependencies for testing.
 */
export const Root = () => (React.createElement(BrowserId, null,
    React.createElement(ReduxProvider, { store: store },
        React.createElement(NetworkProvider, null,
            React.createElement(ThemeProvider, null,
                React.createElement(BrowserRouter, null,
                    React.createElement(Routes, null,
                        React.createElement(Route, { path: "/oauth2/callback", element: React.createElement(OAuth2Callback, null) }),
                        React.createElement(Route, { path: "/setup", element: React.createElement(Setup, null) }),
                        React.createElement(Route, { path: "/login", element: React.createElement(Login, null) }),
                        React.createElement(Route, { path: "/logout", element: React.createElement(LogoutSplash, null) }),
                        React.createElement(Route, { path: "/", element: React.createElement(RegisteredClient, null,
                                React.createElement(App, null)) },
                            React.createElement(Route, { path: "", element: React.createElement(ChatWindow, null) }),
                            React.createElement(Route, { path: "chat", element: React.createElement(ChatWindow, null) },
                                React.createElement(Route, { path: ":accountId" },
                                    React.createElement(Route, { path: "", element: React.createElement(Chat, null) }),
                                    React.createElement(Route, { path: "rides" },
                                        React.createElement(Route, { path: "", element: React.createElement(ViewRides, null) }),
                                        React.createElement(Route, { path: "new", element: React.createElement(RequestRide, null) }),
                                        React.createElement(Route, { path: ":rideId", element: React.createElement(ManageRide, null) })))),
                            React.createElement(Route, { path: "audit", element: React.createElement(AuditWindow, null) },
                                React.createElement(Route, { path: "replies", element: React.createElement(NonStandardReplies, null) }),
                                React.createElement(Route, { path: "follow-up", element: React.createElement(FollowUp, null) })),
                            React.createElement(Route, { path: "settings", element: React.createElement(Settings, null) }),
                            React.createElement(Route, { path: "help", element: React.createElement(Help, null) })))))))));
