import { gql, useQuery, makeVar } from '@apollo/client';
/**
 * Query to fetch all staff users. (Admin only.)
 */
export const ALL_STAFF = gql `
  query AllStaff($limit: Int!, $offset: Int!) {
    staff(limit: $limit, offset: $offset) {
      id
      fullName
      roles
      isActive
      type
      devices {
        id
        address
        type
      }
    }
    hasMoreStaff @client
  }
`;
/**
 * Hook to fetch all staff users. (Admin only.)
 */
export const useAllStaff = (opts) => useQuery(ALL_STAFF, opts);
/**
 * Local schema extension to support querying `hasMoreStaff` variable.
 */
export const allStaffSchemaExtension = gql `
  extend type Query {
    hasMoreStaff: Boolean!
  }
`;
/**
 * Reactive variable to track whether we can query more staff users.
 */
export const hasMoreStaff = makeVar(true);
