import React, { useState, useEffect } from 'react';
import { Spin, Select, Alert, Modal, message, Input, Button, Row, Col, } from 'antd';
import { useParams } from 'react-router-dom';
import { SendOutlined, TranslationOutlined } from '@ant-design/icons';
import { updateOnSend } from '../net/cache/update/send';
import { useSend } from '../net/query/Send.gql';
import { useBrowserId } from '../BrowserId';
import { useTranslation, normLanguage, Languages } from '../Translate';
import { useClientInfoCtx } from '../ClientInfoProvider';
import { errorMessage } from '../util/error';
/**
 * Keyboard keyCode for the ENTER key.
 */
const KEY_ENTER = 13;
/**
 * Tidy up the message before sending.
 */
const clean = (msg) => {
    return msg
        .replace('\r\n', '\n')
        .replace(/\u00A0/g, ' ')
        .trim();
};
/**
 * Message input box for the chat thread.
 */
export const ChatInput = ({ disable }) => {
    const { accountId } = useParams();
    const deviceId = useBrowserId();
    const [doTranslate, requestTranslation] = useState(false);
    const [msg, setMsg] = useState('');
    const [send, { error }] = useSend({
        update: updateOnSend,
    });
    const { loading, clientInfo } = useClientInfoCtx();
    const [lang, setLang] = useState('en');
    const translation = useTranslation({
        skip: !doTranslate,
        text: `[This message was translated by Google.] ${msg}`,
        into: lang,
    });
    useEffect(() => {
        if (!loading) {
            setLang(normLanguage(clientInfo?.language || 'en'));
        }
    }, [loading]);
    /**
     * Run the send message mutation!
     */
    const sendIt = (e) => {
        // Prevent the native "enter" keyboard event from creating a newline.
        e.preventDefault();
        // alt+enter adds a newline to the message.
        if ('keyCode' in e && e.keyCode === KEY_ENTER && e.altKey) {
            setMsg(e.target.value + '\n');
            return;
        }
        // Remove weird characters / spacing from a message.
        const cleanMsg = clean(msg);
        // Don't send an empty message.
        if (!cleanMsg || !accountId) {
            return;
        }
        // Clean up the translation and decide whether to use it.
        const cleanTrans = clean(translation.translation || '');
        const includeTranslation = doTranslate &&
            !translation.error &&
            translation.translation &&
            lang !== 'en' &&
            !!cleanTrans;
        const finalMsg = includeTranslation
            ? `${cleanMsg}\n\n${cleanTrans}`
            : cleanMsg;
        send({
            variables: {
                content: finalMsg,
                fromDeviceId: deviceId,
                toAccountId: accountId,
            },
        });
        setMsg('');
        requestTranslation(false);
    };
    useEffect(() => {
        if (!error) {
            return;
        }
        message.error(`Your message was not sent: ${error}`);
    }, [error]);
    if (loading) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { align: "middle" },
            React.createElement(Col, { offset: 1, span: 21 },
                React.createElement(Input.TextArea, { placeholder: "Text message", disabled: !accountId || !!disable, variant: "borderless", value: msg, autoSize: {
                        minRows: 1,
                        // Max really should depend on screen height!
                        maxRows: 15,
                    }, onChange: (e) => setMsg(e.target.value), onPressEnter: sendIt, className: "chat-input" })),
            React.createElement(Col, { span: 1 },
                React.createElement(Button, { disabled: !msg || !accountId || !!disable, icon: React.createElement(SendOutlined, null), type: "link", onClick: sendIt }),
                React.createElement(Button, { disabled: !msg || !translation.canTranslate || !!disable, icon: React.createElement(TranslationOutlined, null), onClick: () => requestTranslation(true), loading: translation.translating, type: "link" }))),
        React.createElement(Modal, { open: doTranslate, title: React.createElement(React.Fragment, null,
                "Translate message into",
                ' ',
                React.createElement(Select, { value: lang, onChange: (v) => setLang(v), options: Languages.map((l) => ({
                        key: l.code,
                        value: l.code,
                        label: l.name,
                    })) })), onOk: sendIt, okText: "Send", okButtonProps: { disabled: translation.translating }, onCancel: () => requestTranslation(false) },
            translation.error ? (React.createElement(Alert, { message: errorMessage(translation.error) })) : null,
            React.createElement("div", null, msg),
            React.createElement("div", { className: "-translation" }, translation.translating ? (React.createElement("div", { className: "spin-container" },
                React.createElement(Spin, null))) : (translation.translation)))));
};
