import { parsePhoneNumber } from 'libphonenumber-js';
import { asError } from './error';
/**
 * Errors that can occur when parsing a phone number.
 */
export var PhoneNumberParseError;
(function (PhoneNumberParseError) {
    PhoneNumberParseError["Empty"] = "EMPTY_NUMBER";
    PhoneNumberParseError["NotANumber"] = "NOT_A_NUMBER";
    PhoneNumberParseError["TooShort"] = "TOO_SHORT";
    PhoneNumberParseError["TooLong"] = "TOO_LONG";
    PhoneNumberParseError["Invalid"] = "INVALID";
    PhoneNumberParseError["Unknown"] = "UNKNOWN_ERROR";
})(PhoneNumberParseError || (PhoneNumberParseError = {}));
/**
 * Default error placeholders for phone number parse errors.
 */
const defaultErrorFallbacks = {
    [PhoneNumberParseError.NotANumber]: 'not a number',
    [PhoneNumberParseError.TooShort]: 'too short',
    [PhoneNumberParseError.TooLong]: 'too long',
    [PhoneNumberParseError.Invalid]: 'not a real phone number',
    [PhoneNumberParseError.Empty]: 'missing number',
    [PhoneNumberParseError.Unknown]: 'unknown error',
};
/**
 * Format a phone number in the given locale.
 */
export const formatPhoneNumber = (n, locale, fallbacks) => {
    const placeholders = { ...defaultErrorFallbacks, ...fallbacks };
    try {
        if (!n) {
            throw new Error(PhoneNumberParseError.Empty);
        }
        const s = parsePhoneNumber(n, locale);
        if (!s.isValid()) {
            throw new Error(PhoneNumberParseError.Invalid);
        }
        return {
            text: s.formatNational(),
            valid: true,
            error: null,
        };
    }
    catch (e) {
        const errorType = asError(e).message;
        if (Object.values(PhoneNumberParseError).includes(errorType)) {
            return {
                error: placeholders[errorType],
                valid: false,
                text: n,
            };
        }
        else {
            return {
                error: placeholders[PhoneNumberParseError.Unknown],
                valid: false,
                text: n,
            };
        }
    }
};
