import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, deleteToken } from 'firebase/messaging';
import { createBrowserHistory } from 'history';
import { Modal } from 'antd';
import * as log from '../util/log';
import { isFgEvent, FgEventType, NewBgEvent, BgEventType } from '../bg/events';
/**
 * Enforce only one instance of this running.
 */
let _singletonLock = false;
/**
 * URI of service worker.
 */
const SW_PATH = '/sw.js';
/**
 * Wrapper for interacting with the service worker from the main thread.
 *
 * This should be used as a singleton. It can be instantiated anywhere when the
 * app loads.
 */
export class NudgeServiceWorker {
    /**
     * Reference to the initialized Firebase app.
     */
    app = null;
    /**
     * Event handler for push notifications.
     */
    pushHandler = null;
    /**
     * Flag for tracking attachment state to UI.
     */
    detached = false;
    /**
     * Flag for whether a service worker update is available.
     */
    needsUpdate = false;
    /**
     * How often to check for service worker updates (milliseconds).
     */
    updatePollingInterval = 5 * 60 * 1000;
    /**
     * Reference to modal prompt.
     */
    modal = null;
    /**
     * Copy of the config used to register the push handler.
     */
    config = null;
    /**
     * Load the service worker.
     *
     * If a service worker has already been called, this fails.
     */
    constructor() {
        if (_singletonLock) {
            throw new Error("Service worker can't be instantiated twice!");
        }
        // Some environments, like mobile and incognito, don't support service
        // workers. We'll need a workaround for those; not all features of the app
        // will be supported (media messages, push notifications, etc.).
        if (!navigator.serviceWorker) {
            console.warn('Service worker not supported in this environment!');
            return;
        }
        // Prevent any other instance from being created.
        _singletonLock = true;
        navigator.serviceWorker.register(SW_PATH);
        this.installSysHandler();
        this.detached = true;
        // Poll for updates periodically
        setInterval(async () => {
            const reg = await this.ready();
            if (!reg) {
                return;
            }
            await reg.update();
            this.needsUpdate = !!reg.waiting;
            if (this.needsUpdate) {
                this.update();
            }
        }, this.updatePollingInterval);
    }
    /**
     * Force this and all other Nudge web app tabs to reload when necessary in
     * order to apply a service worker update.
     */
    async update() {
        const reg = await this.ready();
        if (!reg) {
            return;
        }
        this.needsUpdate = false;
        const waiting = reg.waiting;
        if (!waiting) {
            log.warn('Service worker was marked as ready for update but did not have any waiting registration!');
            return;
        }
        if (this.modal) {
            return;
        }
        this.modal = Modal.confirm({
            title: 'Update available',
            content: 'Nudge has been updated! This page needs to be reloaded, or else certain features might not work properly.',
            okText: 'Reload',
            onCancel: () => {
                this.modal = null;
            },
            onOk: () => {
                this.modal = null;
                waiting.postMessage(NewBgEvent(BgEventType.Update, {}));
                window.location.reload();
            },
        });
    }
    /**
     * Get the active/ready service worker registration.
     */
    async ready() {
        if (!navigator.serviceWorker) {
            return undefined;
        }
        const reg = await navigator.serviceWorker.ready;
        if (!reg?.active) {
            throw new Error('Failed to find active service worker handle!');
        }
        return reg;
    }
    /**
     * Register to get push notifications in the UI.
     *
     * Do this when a user logs in to establish event listeners.
     *
     * Calling this multiple times will install a new push handler, and might
     * also produce a new token. (It's not necessarily an error to call attach
     * even if the UI is already attached.)
     */
    async attach(cfg, handler) {
        if (this.pushHandler) {
            log.warn('A push handler is already attached! Replacing it.');
            this.removePushHandler();
        }
        this.config = cfg;
        let token = '';
        if (!cfg.projectId) {
            log.warn('Project ID is missing from FCM config, so not attempting to register for push notifications.');
        }
        else {
            token = await this.getPushToken(this.config);
            if (!token) {
                // Suppress error if we know why the token failed.
                if (!navigator.serviceWorker) {
                    return '';
                }
                throw new Error('Failed to register to receive push notifications!');
            }
        }
        this.installPushHandler(handler);
        this.detached = false;
        // Make sure push handlers are instantiated immediately.
        const reg = await this.ready();
        if (!reg?.active) {
            return token;
        }
        reg.active.postMessage(NewBgEvent(BgEventType.RegisterPush, cfg));
        return token;
    }
    /**
     * Remove event listeners, e.g. when a user logs out.
     */
    async detach() {
        if (this.detached) {
            return;
        }
        this.config = null;
        this.removePushHandler();
        const reg = await this.ready();
        if (!reg?.active) {
            return;
        }
        reg.active.postMessage(NewBgEvent(BgEventType.Logout, {}));
        this.detached = true;
        return deleteToken(getMessaging());
    }
    /**
     * Update the access token in the service worker's memory.
     */
    async updateAccessToken(token) {
        const reg = await this.ready();
        if (!reg) {
            console.error("Service worker registration wasn't available, did not update access token.");
            return;
        }
        reg.active.postMessage(NewBgEvent(BgEventType.AccessToken, { token }));
        return;
    }
    /**
     * Get a reference to the firebase app, instantiating if needed.
     */
    getApp(cfg) {
        if (this.app) {
            return this.app;
        }
        this.app = initializeApp(cfg);
    }
    /**
     * Remove the push event listener.
     */
    removePushHandler() {
        if (!this.pushHandler || !navigator.serviceWorker) {
            return;
        }
        navigator.serviceWorker.removeEventListener('message', this.pushHandler);
        this.pushHandler = null;
    }
    /**
     * Use the given handler for push events.
     *
     * Not a public interface; don't call this directly. Use `attach` instead.
     */
    installPushHandler(handler) {
        if (this.pushHandler) {
            throw new Error('A push handler is already registered!');
        }
        if (!navigator.serviceWorker) {
            throw new Error('Service worker not supported in this environment!');
        }
        // Create a new push handler
        this.pushHandler = (e) => {
            const data = e.data;
            if (!data) {
                log.warn('Service worker event missing data', JSON.stringify(e));
                return;
            }
            // Treat data as an array of events
            const arr = Array.isArray(data) ? data : [data];
            for (const evt of arr) {
                if (isFgEvent(evt)) {
                    if (evt.message.startsWith('msg.')) {
                        handler(evt);
                    }
                }
                else {
                    log.warn('Unsure how to handle event', JSON.stringify(evt));
                }
            }
        };
        // Install the handler
        navigator.serviceWorker.addEventListener('message', this.pushHandler);
    }
    /**
     * Get a valid FCM push token registered to the service worker.
     */
    async getPushToken(cfg) {
        const app = this.getApp(cfg);
        const reg = await this.ready();
        if (!reg) {
            return '';
        }
        return getToken(getMessaging(app), {
            vapidKey: cfg.messagingPublicKey,
            serviceWorkerRegistration: reg,
        });
    }
    /**
     * Install a message handler for system events.
     *
     * This is intended to be called just once. The system handler exists
     * regardless of attachment / push registration.
     */
    installSysHandler() {
        if (!navigator.serviceWorker) {
            throw new Error('Service worker not supported in this environment!');
        }
        navigator.serviceWorker.addEventListener('message', (e) => {
            const data = e.data;
            if (!data) {
                return;
            }
            if (!isFgEvent(data)) {
                return;
            }
            switch (data.message) {
                case FgEventType.SysLogout:
                    // Only force a logout if this UI is not already logged out,
                    // otherwise there would be an infinite loop.
                    if (!this.detached) {
                        window.location.pathname = '/logout';
                    }
                    return;
                default:
                    // Ignore any other message.
                    return;
            }
        });
        // TODO -- this is the ideal way to apply changes, but it doesn't work
        // because react-router-dom has no way of accessing the history object.
        // Apply service worker updates automatically when navigating.
        const history = createBrowserHistory({ window });
        history.listen(() => {
            this.update();
        });
    }
}
