import { gql } from '@apollo/client';
/**
 * Fragment to pull info about a ride.
 */
export const RIDE_DATA = gql `
  fragment RideData on Ride {
    id
    status
    scheduledTime
    caseNumber
    cost
    receiptReceived
    created
    updated
  }
`;
