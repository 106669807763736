/**
 * Google Translate client.
 */
export class GoogleTranslate {
    apiKey;
    url = 'https://translation.googleapis.com/language/translate/v2';
    constructor(apiKey) {
        this.apiKey = apiKey;
        this.apiKey = apiKey;
    }
    /**
     * Call Google Translate with the given text, translating into the given
     * language. Optionally provide a source language. If no source is provided,
     * then it will be auto-detected by Google.
     */
    async translate(text, into = 'en', source) {
        const parts = [
            ['key', this.apiKey],
            ['format', 'text'],
            ['target', into],
            ['q', text],
        ];
        if (source) {
            parts.push(['source', source]);
        }
        const req = parts
            .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
            .join('&');
        const url = `${this.url}?${req}`;
        const result = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'force-cache',
            redirect: 'follow',
        });
        if (result.status !== 200) {
            throw new Error(result.statusText);
        }
        return (await result.json());
    }
}
