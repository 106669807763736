/**
 * Get a copy of a list that includes the given value.
 */
export const withValue = (list, value) => {
    if (list.includes(value)) {
        return [...list];
    }
    return [...list, value];
};
/**
 * Get a copy of a list that excludes a given value.
 */
export const withoutValue = (list, value) => {
    if (!list.includes(value)) {
        return [...list];
    }
    return list.filter((x) => x !== value);
};
