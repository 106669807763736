import { gql, useQuery } from '@apollo/client';
/**
 * Fetch devices for a staff user.
 */
export const MY_DEVICES = gql `
  query MyDevices($id: ID!) {
    node(id: $id) {
      ... on StaffAccount {
        devices {
          id
          address
          type
          receiveUnknownMessages
        }
      }
    }
  }
`;
/**
 * Hook to fetch a user's devices.
 */
export const useMyDevices = (opts) => useQuery(MY_DEVICES, opts);
