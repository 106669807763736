import { getMessageThreadId, insertMessage, } from '../message';
import { SENT_MSG_CONTENT } from '../../query/fragments/SentMessage.gql';
import * as log from '../../logger';
export const updateOnSend = (cache, { data }) => {
    if (!data?.sendSms) {
        return;
    }
    const sendSms = data.sendSms;
    // Get the destination account from the query result. This should be the
    // same as the query arguments, but it's theoretically possible the backend
    // rerouted the message to a different account for some reason.
    const realDestAccountId = sendSms.destination?.account?.id;
    if (!realDestAccountId) {
        log.warn('Failed to update the cache because destination account ID was missing from result!');
        return;
    }
    // Update the message thread to include this new message.
    cache.modify({
        id: 'ROOT_QUERY',
        fields: {
            [getMessageThreadId(realDestAccountId)](history = []) {
                const newRef = cache.writeFragment({
                    id: `Message:${sendSms.id}`,
                    fragment: SENT_MSG_CONTENT,
                    data: data.sendSms,
                });
                return insertMessage(history.slice(), newRef, {
                    cache,
                });
            },
        },
    });
    // Update the latest message in the thread pointer to be this new message.
    cache.modify({
        id: `ClientAccount:${realDestAccountId}`,
        fields: {
            lastMessage() {
                // NOTE: message is *not* written into the cache here, since we
                // expect it to be written in the message thread update cache
                // modification call above.
                return { __ref: `Message:${sendSms?.id}` };
            },
        },
    });
};
