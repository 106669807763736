import React from 'react';
export const About = () => (React.createElement("div", null,
    React.createElement("p", null,
        "Nudge is built and maintained by the",
        ' ',
        React.createElement("a", { href: "http://policylab.stanford.edu", rel: "noopener noreferrer", target: "_blank" }, "Stanford Computational Policy Lab"),
        "."),
    React.createElement("p", null,
        "For questions, feedback, and bug reports, please reach out to us by email at",
        ' ',
        React.createElement("a", { href: "mailto:nudge-help@lists.stanford.edu", rel: "noopener noreferrer", target: "_blank" }, "nudge-help@lists.stanford.edu"),
        "."),
    React.createElement("p", null, "The primary purpose of Nudge is to offer automated reminders to clients about court dates, in order to reduce failures-to-appear. We offer this web interface to supplement those reminders with additional manual messages, as necessary."),
    React.createElement("p", null, "All messages sent through Nudge are delivered through the same phone number, which helps streamline the experience of the clients and limit confusion.")));
