import { gql } from '@apollo/client';
/**
 * Fragment to pull fields from a sent message.
 */
export const SENT_MSG_CONTENT = gql `
  fragment SentMessage on Message {
    id
    content
    sent
    seq
    status
    errorCode
    mediaUrls {
      uri
      contentType
    }
    destination {
      account {
        id
      }
      device {
        id
      }
    }
    source {
      account {
        fullName
      }
    }
  }
`;
