import React from 'react';
import { useParams } from 'react-router-dom';
import { Descriptions, Button, Tag, message } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useSafeNavigate as useNavigate } from '../util/safeNavigate';
import { RideParty } from '../net/types/graphql';
import { LoadingContainer } from '../LoadingContainer';
import { GoogleMapsApiProvider } from '../Transit/GoogleMapsApi';
import { useRideDetails } from '../net/query/RideDetails.gql';
import { useCancelRide } from '../net/query/CancelRide.gql';
import { useClientInfoCtx } from '../ClientInfoProvider';
import { useContainerSize } from '../util/useContainerSize';
import { styleForStatus } from './style';
/**
 * Check whether we can cancel the ride on this page.
 */
const canCancelFromUI = (rideInfo) => {
    if (!rideInfo?.canCancel) {
        return false;
    }
    return rideInfo?.canCancel.indexOf(RideParty.Dispatcher) > -1;
};
const ManageRideInternal = ({ rideInfo, clientInfo }) => {
    const { rideId } = useParams();
    const container = useContainerSize();
    const navigate = useNavigate();
    const [cancelRide, { loading: canceling }] = useCancelRide();
    if (!rideInfo || !clientInfo) {
        return null;
    }
    // The ride is guaranteed to be here by the outer component, which does
    // validation and reports an error if something isn't right.
    const ride = clientInfo.rides.find((r) => r.id === rideId);
    if (!ride) {
        throw new Error('ride not found');
    }
    const style = styleForStatus(rideInfo.status);
    return (React.createElement(GoogleMapsApiProvider, null,
        React.createElement("div", { id: "nudge-view-rides", ref: container.ref },
            React.createElement(PageHeader, { avatar: { src: rideInfo.passenger.imageUrl, icon: React.createElement(UserOutlined, null) }, tags: React.createElement(Tag, { color: style.color }, style.text), onBack: () => navigate(`/chat/${clientInfo.id}/rides`), extra: [
                    React.createElement(Button, { key: "cancel", disabled: !canCancelFromUI(rideInfo), onClick: () => {
                            cancelRide({
                                variables: { id: rideInfo.rideId },
                                onCompleted: () => {
                                    message.success('Ride canceled!');
                                },
                            });
                        }, loading: canceling }, "Cancel"),
                ], title: dayjs(ride.scheduledTime).format('LLL') },
                React.createElement(Descriptions, { bordered: true, title: "Ride Info", column: 2 },
                    React.createElement(Descriptions.Item, { label: "Case number" }, ride.caseNumber || '--'),
                    React.createElement(Descriptions.Item, { label: "Canceled By" }, rideInfo.canceledBy || 'n/a'),
                    React.createElement(Descriptions.Item, { label: "Pick Up" }, rideInfo.origin.address),
                    React.createElement(Descriptions.Item, { label: "Drop Off" }, rideInfo.destination.address),
                    React.createElement(Descriptions.Item, { label: "Price" }, rideInfo.price?.amount ? (React.createElement("a", { href: rideInfo.pricingDetailsUrl || '#', target: "_blank" },
                        "$",
                        rideInfo.price.amount / 100)) : ('--')),
                    React.createElement(Descriptions.Item, { label: "Route URL" }, rideInfo.routeUrl ? (React.createElement("a", { href: rideInfo.routeUrl, target: "_blank" }, rideInfo.routeUrl)) : ('--'))),
                React.createElement("div", null, "TODO: we have lots more info about the ride we can show here, including driver info, current location, and timeline of events.")))));
};
/**
 * Manage an existing ride.
 */
export const ManageRide = () => {
    const { rideId } = useParams();
    const { loading, error, clientInfo } = useClientInfoCtx();
    const { loading: loadingRide, error: errorRide, data: rideDetails, } = useRideDetails({
        variables: { id: rideId || '' },
        fetchPolicy: 'cache-and-network',
    });
    const hasRide = clientInfo?.rides.find((r) => r.id === rideId);
    const anyError = error || errorRide || null;
    const anyLoading = loading || loadingRide;
    return (React.createElement(LoadingContainer, { error: anyError, loading: anyLoading, style: { alignItems: anyLoading ? undefined : 'start' } }, rideDetails?.node?.__typename === 'Ride' && clientInfo && hasRide && (React.createElement(ManageRideInternal, { rideInfo: rideDetails.node.lyftDetails, clientInfo: clientInfo }))));
};
