import { gql, useMutation } from '@apollo/client';
/**
 * Change the FSM state for the given client.
 */
export const UPDATE_FSM_STATE = gql `
  mutation UpdateFsmState($accountId: ID!, $newState: String!) {
    updateFsmState(clientAccountId: $accountId, newState: $newState)
  }
`;
/**
 * Hook for updating client FSM state.
 */
export const useUpdateFsmState = (opts) => useMutation(UPDATE_FSM_STATE, opts);
