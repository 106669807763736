/**
 * Encode a string as base-64 using URL-safe characters.
 *
 * Based on:
 * https://datatracker.ietf.org/doc/html/rfc4648#section-5
 *
 * This is not an efficient implementation, but it's only expected to be used
 * rarely for short pieces of data so it doesn't matter.
 */
export const urlSafeBase64Encode = (s) => {
    let b64 = btoa(s);
    // Strip padding
    b64 = b64.replace(/={1,2}$/, '');
    // Sub - for +
    b64 = b64.replace(/\+/g, '-');
    // Sub _ for /
    b64 = b64.replace(/\//g, '_');
    // Ta-da!
    return b64;
};
