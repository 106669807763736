import { handleMessage } from '../../push/handleMessage';
import * as log from '../../logger';
/**
 * Read a list of unread notifications into the cache.
 */
export const notifications = (getClient) => ({
    keyArgs: [],
    merge(_, incoming, opts) {
        for (const inc of incoming) {
            const payload = opts.readField('payload', inc);
            if (!payload) {
                continue;
            }
            try {
                const parsed = JSON.parse(payload);
                handleMessage(getClient(), '', { data: parsed });
            }
            catch (e) {
                log.warn('Error interpretting unread notification', e);
            }
        }
        return incoming;
    },
});
