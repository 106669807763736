import React, { useMemo, useEffect, useState } from 'react';
import { useApolloClient, } from '@apollo/client';
import { Alert, Button, message } from 'antd';
import * as Sentry from '@sentry/react';
import { useSafeNavigate as useNavigate } from './util/safeNavigate';
import { useAppSelector, useAppDispatch } from './state/hooks';
import { registerPushDevice } from './state/push';
import { useUnreadNotifs } from './net/query/Notification.gql';
import { LoadingContainer } from './LoadingContainer';
import { useBrowserId } from './BrowserId';
/**
 * Component that handles registering this browser app with the server.
 *
 * This step is required in order to send texts and receive push notifications.
 */
export const RegisteredClient = (props) => {
    // NOTE: Notification API is not available everywhere, e.g. on Mobile.
    const [permission, setPermission] = useState(window.Notification ? Notification.permission : 'impossible');
    const client = useApolloClient();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const browserId = useBrowserId();
    const loggedIn = useAppSelector((state) => state.auth.loggedIn);
    const userId = useAppSelector((state) => state.auth.userId);
    const registering = useAppSelector((state) => state.push.registering);
    const error = useAppSelector((state) => {
        const err = state.auth.error || state.push.error;
        return err ? new Error(err || 'unknown error') : null;
    });
    const token = useAppSelector((state) => state.auth.token);
    // Get unread notifications for this account.
    // TODO - might want to poll for these as a failsafe for when push fails.
    if (userId) {
        Sentry.setUser({ id: userId });
        useUnreadNotifs({
            variables: {
                accountId: userId,
            },
        });
    }
    // Redirect users if they aren't logged in.
    useEffect(() => {
        if (!loggedIn) {
            navigate('/login');
        }
    }, [loggedIn]);
    // Register the device if the user is logged in. If they aren't the query
    // will simply fail.
    useEffect(() => {
        if (loggedIn && userId) {
            dispatch(registerPushDevice({ userId, client, deviceId: browserId }));
        }
    }, [loggedIn, userId, browserId, permission]);
    if (permission === 'default') {
        return (React.createElement(Alert, { type: "info", showIcon: true, message: "Please grant permission", description: "Nudge needs your permission to use push services. The app will not work properly without this.", action: React.createElement(Button, { className: "nudge-request-permission", onClick: async () => {
                    setPermission(await Notification.requestPermission());
                } }, "OK") }));
    }
    else if (permission === 'denied') {
        useMemo(() => setTimeout(() => message.error("You've blocked push services on Nudge. You will not be able to see up-to-date messages. You will need to allow notifications manually to use Nudge. Click the notification icon in your search bar to do so."), 0), []);
    }
    return (React.createElement(LoadingContainer, { error: error, data: token, loading: registering }, props.children));
};
