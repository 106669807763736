import { InMemoryCache } from '@apollo/client';
import { messageHistory } from './fieldPolicy/messageHistory';
import { getMessageHistory } from './fieldPolicy/getMessageHistory';
import { node } from './fieldPolicy/node';
import { followUpChats } from './fieldPolicy/followUpChats';
import { hasMoreFollowUps } from './fieldPolicy/hasMoreFollowUps';
import { hasMoreMessages } from './fieldPolicy/hasMoreMessages';
import { hasMoreClients } from './fieldPolicy/hasMoreClients';
import { hasMoreStaff } from './fieldPolicy/hasMoreStaff';
import { clients } from './fieldPolicy/clients';
import { staff } from './fieldPolicy/staff';
import { source, destination } from './fieldPolicy/Message';
import { status } from './fieldPolicy/Ride';
import { unreadMessages } from './fieldPolicy/unreadMessages';
import { unreadCount } from './fieldPolicy/unreadCount';
import { notifications } from './fieldPolicy/notifications';
/**
 * Initialize an Apollo cache.
 *
 * The cache is heavily tuned with merge/read logic. A plain instance of the
 * InMemoryCache will *not* work with Nudge!
 */
export const initCache = (getApolloClient) => new InMemoryCache({
    typePolicies: {
        StaffAccount: {
            fields: {
                notifications: notifications(getApolloClient),
                unreadCount,
                followUpChats,
                hasMoreFollowUps,
            },
        },
        ClientAccount: {
            fields: {
                messageHistory,
                unreadMessages,
            },
        },
        AssociateAccount: {
            fields: {
                messageHistory,
            },
        },
        Message: {
            fields: {
                destination,
                source,
            },
        },
        Ride: {
            fields: {
                status,
            },
        },
        Query: {
            fields: {
                getMessageHistory,
                node,
                hasMoreMessages,
                hasMoreClients,
                hasMoreStaff,
                clients,
                staff,
            },
        },
    },
});
