import React from 'react';
import { Switch, Table, Input } from 'antd';
import { useAppSelector } from '../state/hooks';
import { useAllStaff } from '../net/query/AllStaff.gql';
import { useUpdateStaff } from '../net/query/UpdateStaff.gql';
import { AccountType, Role } from '../net/types/graphql';
import { withValue, withoutValue } from '../util/list';
/**
 * View to manage a list of staff users.
 */
export const ManageStaff = () => {
    const [updateStaff, { loading: updatingStaff }] = useUpdateStaff();
    const { userId, roles } = useAppSelector(({ auth }) => ({
        userId: auth.userId,
        roles: auth.roles || [],
    }));
    const { data, loading, error } = useAllStaff({
        skip: !roles.includes(Role.Admin),
        variables: {
            offset: 0,
            // Load everyone up front, effectively.
            // TODO: API is limited because we can't search by name, since we don't
            // store name directly. So virtualized list would be impossible to sort
            // in a sensible way.
            limit: 10000,
        },
    });
    if (error) {
        return React.createElement("div", null,
            "Error: ",
            error.message);
    }
    if (!data?.staff && !loading) {
        return React.createElement("div", null, "Error: No Data");
    }
    // Filter out any failed accounts (this indicates a mismatch between our database
    // and the upstream org database, usually).
    // Also filter out BOT users which don't need to be managed here.
    // Lastly, sort by name (not last name, for now).
    const rows = (data?.staff || [])
        .filter((s) => !!s.fullName && s.type !== AccountType.Bot)
        .sort((a, b) => a.fullName.toLowerCase() < b.fullName.toLowerCase() ? -1 : 1);
    return (React.createElement(Table, { dataSource: rows, rowKey: (x) => x.id, pagination: { pageSize: 10, showSizeChanger: false }, loading: loading || updatingStaff, columns: [
            {
                title: 'Active',
                key: 'isActive',
                dataIndex: 'isActive',
                render: (_, staff) => (React.createElement(Switch, { disabled: staff.id === userId, checked: staff.isActive || false, onClick: (checked) => updateStaff({
                        variables: {
                            id: staff.id,
                            isActive: checked,
                            roles: staff.roles,
                        },
                    }) })),
            },
            {
                title: 'Name',
                key: 'fullName',
                dataIndex: 'fullName',
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (React.createElement("div", { style: { padding: 8 }, onKeyDown: (e) => e.stopPropagation() },
                    React.createElement(Input.Search, { value: selectedKeys[0], allowClear: true, onChange: (e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }, onSearch: () => {
                            confirm();
                            close();
                        } }))),
                onFilter: (v, record) => {
                    // Compile pattern from input. Escape special characters and expand
                    // spaces into whitespace wildcards.
                    const pat = `${v}`
                        .replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')
                        .replace(/\s+/g, '\\s+');
                    const exp = new RegExp(pat, 'ig');
                    return exp.test(record.fullName);
                },
            },
            {
                title: 'Admin',
                key: 'admin',
                dataIndex: 'roles',
                render: (_, staff) => (React.createElement(Switch, { disabled: staff.id === userId || !staff.isActive, checked: staff.roles.includes(Role.Admin), onClick: (checked) => updateStaff({
                        variables: {
                            id: staff.id,
                            isActive: staff.isActive,
                            roles: checked
                                ? withValue(staff.roles.slice(), Role.Admin)
                                : withoutValue(staff.roles.slice(), Role.Admin),
                        },
                    }) })),
            },
            {
                title: 'Staff',
                key: 'staff',
                dataIndex: 'roles',
                render: (_, staff) => (React.createElement(Switch, { disabled: !staff.isActive, checked: staff.roles.includes(Role.Staff), onClick: (checked) => updateStaff({
                        variables: {
                            id: staff.id,
                            isActive: staff.isActive,
                            roles: checked
                                ? withValue(staff.roles.slice(), Role.Staff)
                                : withoutValue(staff.roles.slice(), Role.Staff),
                        },
                    }) })),
            },
        ] }));
};
