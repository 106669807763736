import React, { useEffect } from 'react';
import { useApolloClient, } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Layout } from 'antd';
import * as log from './util/log';
import { useBrowserId } from './BrowserId';
import { useSafeNavigate as useNavigate } from './util/safeNavigate';
import { useAppDispatch, useAppSelector } from './state/hooks';
import { reset } from './state/auth';
import { unregisterPushDevice } from './state/push';
/**
 * Page that performs the logout steps.
 *
 * The UI is transitory, the main point is to stop requests and clean up
 * the local state (cache, store, etc) to unregister the current user.
 */
export const LogoutSplash = () => {
    // Clear logged-in user for error logging.
    Sentry.configureScope((scope) => scope.setUser(null));
    const deviceId = useBrowserId();
    const apollo = useApolloClient();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userId = useAppSelector((state) => state.auth.userId);
    // Do all the unregistration stuff.
    const unregister = async () => {
        try {
            try {
                await dispatch(unregisterPushDevice({ client: apollo, userId, deviceId }));
            }
            catch (e) {
                log.error(`Error unregistering push device: ${e}`);
            }
            await apollo.stop();
            await apollo.clearStore();
            await dispatch(reset());
        }
        catch (e) {
            log.error(`Error logging out: ${e}`);
        }
        finally {
            navigate('/login');
        }
    };
    useEffect(() => {
        unregister();
    }, []);
    return (React.createElement(Layout, { id: "nudge-root" },
        React.createElement(Layout.Content, { style: { padding: '2em' } },
            React.createElement("p", null, "Logging you out ..."),
            React.createElement("p", null,
                "You should be redirected to the login screen momentarily. Click",
                ' ',
                React.createElement("a", { href: "/login" }, "here"),
                " if you don't get redirected automatically."))));
};
