import React from 'react';
import { CheckOutlined, CarOutlined, CloseOutlined, StopOutlined, ClockCircleOutlined, QuestionOutlined, } from '@ant-design/icons';
import { RideStatus } from '../net/types/graphql';
/**
 * Determine style relate to a Ride Status.
 */
export const styleForStatus = (s) => {
    let text = s ? s : 'Not scheduled';
    let icon = React.createElement(CarOutlined, null);
    let color = 'var(--nudge-info-color)';
    let title = 'No ride is currently scheduled.';
    switch (s) {
        // SUCCESS STATUSES
        case RideStatus.Accepted:
            text = 'Accepted';
            icon = React.createElement(CheckOutlined, null);
            color = 'var(--nudge-success-color)';
            title = 'A driver has accepted this ride.';
            break;
        case RideStatus.Arrived:
            text = 'Arrived';
            icon = React.createElement(CarOutlined, null);
            color = 'var(--nudge-success-color)';
            title = 'Driver has arrived to pick up client.';
            break;
        case RideStatus.PickedUp:
            text = 'Picked Up';
            icon = React.createElement(CarOutlined, null);
            color = 'var(--nudge-success-color)';
            title = 'Client has been picked up and is in transit.';
            break;
        case RideStatus.DroppedOff:
            text = 'Dropped Off';
            icon = React.createElement(CheckOutlined, null);
            color = 'var(--nudge-success-color)';
            title = 'Client has been dropped off.';
            break;
        // FAILURE STATUSES
        case RideStatus.Canceled:
            text = 'Canceled';
            icon = React.createElement(StopOutlined, null);
            color = 'var(--nudge-error-color)';
            title = 'Ride was canceled.';
            break;
        case RideStatus.Failed:
            text = 'Failed';
            icon = React.createElement(CloseOutlined, null);
            color = 'var(--nudge-error-color)';
            title = 'Ride failed due to issues with Lyft.';
            break;
        case RideStatus.Unknown:
            text = 'Unknown';
            icon = React.createElement(QuestionOutlined, null);
            color = 'var(--nudge-error-color)';
            title = 'Ride status is unknown.';
            break;
        // PROGRESS STATUSES
        case RideStatus.Pending:
            text = 'Pending';
            icon = React.createElement(ClockCircleOutlined, null);
            color = 'var(--nudge-danger-color)';
            title = 'Ride has been requested.';
            break;
        case RideStatus.Scheduled:
            text = 'Scheduled';
            icon = React.createElement(ClockCircleOutlined, null);
            color = 'var(--nudge-info-color)';
            title = 'Ride is scheduled.';
            break;
    }
    return { text, icon, color, title };
};
