import React from 'react';
import { Button, Space, Typography, Table, Row } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import dayjs from 'dayjs';
import { useSafeNavigate as useNavigate } from '../util/safeNavigate';
import { useClientInfoCtx } from '../ClientInfoProvider';
import { findRideForCourtDate } from './find';
import { styleForStatus } from './style';
/**
 * Render list of scheduled rides.
 */
export const ViewRides = () => {
    const navigate = useNavigate();
    const { loading, error, clientInfo } = useClientInfoCtx();
    if (loading) {
        return null;
    }
    if (error || !clientInfo) {
        return React.createElement(React.Fragment, null,
            "Error loading client info: ",
            error?.message);
    }
    const cdWithRides = clientInfo.courtDates.map((cd) => ({
        ...cd,
        courtDate: dayjs(cd.courtDate),
        firstRide: findRideForCourtDate(cd, clientInfo.rides, 'to'),
        returnRide: findRideForCourtDate(cd, clientInfo.rides, 'from'),
    }));
    // TODO can improve performance pretty easily
    const otherRides = clientInfo.rides.filter((r) => !cdWithRides.some((cd) => cd.firstRide?.id === r.id || cd.returnRide?.id === r.id));
    const cols = [
        {
            title: 'Court Date',
            dataIndex: 'courtDate',
            key: 'courtDate',
            render(_, item) {
                return React.createElement(React.Fragment, null, item.courtDate.format('LLL'));
            },
        },
        {
            title: 'Case Number',
            dataIndex: 'caseNumber',
            key: 'caseNumber',
        },
        {
            title: 'Ride to court',
            dataIndex: 'firstRide',
            render(_, record) {
                if (record?.firstRide) {
                    const style = styleForStatus(record.firstRide.status);
                    return (React.createElement(Button, { onClick: () => navigate(`/chat/${clientInfo.id}/rides/${record.firstRide.id}`), icon: style.icon, style: { color: style.color } }, style.text));
                }
                return (React.createElement(Button, { onClick: () => navigate(`/chat/${clientInfo.id}/rides/new?d=to`, {
                        state: JSON.stringify(record),
                    }), type: "primary" }, "Schedule"));
            },
        },
        {
            title: 'Ride from court',
            dataIndex: 'return',
            render(_, record) {
                const { returnRide } = record;
                if (returnRide) {
                    const style = styleForStatus(returnRide.status);
                    return (React.createElement(Button, { onClick: () => navigate(`/chat/${clientInfo.id}/rides/${returnRide.id}`), icon: style.icon, style: { color: style.color } }, style.text));
                }
                return (React.createElement(Button, { onClick: () => navigate(`/chat/${clientInfo.id}/rides/new?d=from`, {
                        state: JSON.stringify(record),
                    }), type: "primary" }, "Schedule"));
            },
        },
    ];
    // Table to display rides not matched to a specific court date.
    const otherRideCols = [
        {
            title: 'Pickup',
            dataIndex: 'scheduledTime',
            render(_, { scheduledTime }) {
                return dayjs(scheduledTime).format('LLL');
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render(_, record) {
                const style = styleForStatus(record.status);
                return (React.createElement(Button, { onClick: () => navigate(`/chat/${clientInfo.id}/rides/${record.id}`), icon: style.icon, style: { color: style.color } }, style.text));
            },
        },
    ];
    return (React.createElement("div", { id: "nudge-view-rides" },
        React.createElement(PageHeader, { onBack: () => navigate(`/chat/${clientInfo.id}`), title: "Scheduled rides" },
            React.createElement(Row, { style: { flex: 1 } },
                React.createElement(Table, { rowKey: (record) => `${record.caseNumber}-${record.courtDate.unix()}`, pagination: false, style: { width: '100%' }, dataSource: cdWithRides, columns: cols })),
            React.createElement(Space, null),
            otherRides.length > 0 ? (React.createElement(Row, { style: { flex: 1 } },
                React.createElement(Typography.Title, { level: 5 }, "Other rides"),
                React.createElement(Typography.Paragraph, null, "We have a record of the following rides, but they are not associated with any current court case."),
                React.createElement(Table, { rowClassName: () => '-click', rowKey: (record) => record.id, style: { width: '100%' }, dataSource: otherRides, columns: otherRideCols }))) : null)));
};
