import React, { useEffect } from 'react';
import { Layout, Input, Form, Button } from 'antd';
import { useAppDispatch, useAppSelector } from './state/hooks';
import { updateBaseUrl } from './state/baseUrl';
import { useSafeNavigate as useNavigate } from './util/safeNavigate';
/**
 * First time app setup.
 */
export const Setup = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const baseUrlState = useAppSelector((state) => state.baseUrl);
    useEffect(() => {
        if (baseUrlState.validated) {
            navigate('/login');
        }
    }, [baseUrlState.validated]);
    return (React.createElement(Layout, { id: "nudge-setup" },
        React.createElement(Layout.Header, { id: "nudge-header" }, "Setup"),
        React.createElement(Layout, null,
            React.createElement(Layout.Content, { id: "nudge-body" },
                React.createElement("p", null, "Welcome to Nudge! This app needs a small amount of configuration before you can use it."),
                React.createElement("p", null, "Please enter the URL of the Nudge API you wish to connect to. We will save this URL on your computer so that you don't need to enter it more than once."),
                React.createElement(Form, { name: "setup", labelCol: { span: 8 }, wrapperCol: { span: 10 }, initialValues: {
                        baseUrl: baseUrlState.value,
                    }, onFinish: (values) => dispatch(updateBaseUrl(values.baseUrl)) },
                    React.createElement(Form.Item, { label: "Nudge API", name: "baseUrl" },
                        React.createElement(Input, { placeholder: "nudge.mydepartment.org", addonAfter: React.createElement("span", null, baseUrlState.validating
                                ? 'L'
                                : baseUrlState.error
                                    ? 'E'
                                    : baseUrlState.validated
                                        ? 'V'
                                        : 'N') })),
                    React.createElement(Form.Item, { wrapperCol: { offset: 8 } },
                        React.createElement(Button, { loading: baseUrlState.validating, type: "primary", htmlType: "submit" }, "Save")))))));
};
