import React, { useEffect, useState, useContext } from 'react';
import { v4 as uuid4 } from 'uuid';
/**
 * React context to provide browser ID.
 */
const BrowserIdContext = React.createContext(null);
/**
 * ID in the local cache where browser ID will be stored.
 */
const ID_CACHE_KEY = '$nudgeBrowserId';
/**
 * Generate a new globally unique ID.
 */
const getNewId = () => uuid4();
/**
 * Pull existing ID from the local storage if it's available.
 */
const getStoredId = async () => {
    return localStorage.getItem(ID_CACHE_KEY) || null;
};
/**
 * Save an ID in local storage.
 */
const saveId = async (id) => {
    if (!id) {
        throw new Error('invalid browser ID');
    }
    return localStorage.setItem(ID_CACHE_KEY, id);
};
/**
 * Get the browser ID.
 */
export const getBrowserId = async () => {
    const existingId = await getStoredId();
    if (existingId) {
        return existingId;
    }
    const newId = getNewId();
    await saveId(newId);
    return newId;
};
/**
 * Hook to provide the browser ID from the containing context.
 */
export const useBrowserId = () => useContext(BrowserIdContext);
/**
 * Context provider component that provides a browser ID.
 */
export const BrowserId = ({ children }) => {
    const [browserId, setBrowserId] = useState(null);
    useEffect(() => {
        getBrowserId().then((id) => setBrowserId(id));
    }, []);
    if (!browserId) {
        return null;
    }
    return (React.createElement(BrowserIdContext.Provider, { value: browserId }, children));
};
