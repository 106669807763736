/**
 * Update the cache after unregistering a device.
 */
export const updateOnUnregisterDevice = (cache, { errors }, { variables }) => {
    if (errors?.length) {
        return;
    }
    if (!variables?.deviceId) {
        return;
    }
    // Remove the device from the cache.
    cache.evict({
        id: cache.identify({ __typename: 'Device', id: variables.deviceId }),
    });
};
