import { gql, useMutation } from '@apollo/client';
export const LOGIN = gql `
  mutation Login {
    login {
      id
      roles
    }
  }
`;
/**
 * Hook for login mutation.
 */
export const useLogin = (opts) => useMutation(LOGIN, opts);
