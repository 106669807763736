import { getMessageCacheId } from '../message';
/**
 * Update a cached message with the updated status.
 */
export const updateMsgStatusFromPush = (cache, pushData) => {
    cache.modify({
        id: getMessageCacheId(pushData.msg_id),
        fields: {
            status() {
                // Replace the status with what the push says.
                return pushData.status;
            },
        },
    });
};
