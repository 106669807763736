/**
 * Update ride status of canceled ride.
 */
export const updateOnCancelRide = (cache, { errors }, { variables }) => {
    if (errors?.length) {
        return;
    }
    if (!variables?.id) {
        return;
    }
    cache.modify({
        id: `Ride:${variables.id}`,
        fields: {
            status() {
                return 'CANCELED';
            },
        },
    });
};
