import { hasMoreClients } from '../../query/AllClients.gql';
/**
 * Field policy for merging pages of AllClients results.
 */
export const clients = {
    keyArgs: ['search'],
    // Merge paginated queries into the cached data directly.
    // See: https://www.apollographql.com/docs/react/pagination/core-api/#defining-a-field-policy
    merge(existing = [], incoming, opts) {
        const offset = opts.args?.offset || 0;
        const limit = opts.args?.limit || 0;
        const merged = existing.slice();
        // If the query returned less data than requested, mark that
        // it is finished producing.
        if (incoming.length < limit) {
            hasMoreClients(false);
        }
        else {
            hasMoreClients(true);
        }
        // Merge incoming data using the precise offset in case query
        // is loaded multiple times, or out of order.
        for (let i = 0; i < incoming.length; i++) {
            // Note that some clients might technically be null if there
            // was an error that occurred on the backend loading it.
            merged[offset + i] = incoming[i];
        }
        return merged;
    },
};
