import { gql, useMutation, } from '@apollo/client';
import { SENT_MSG_CONTENT } from './fragments/SentMessage.gql';
/**
 * Mutation to send a message (SMS).
 */
export const SEND = gql `
  ${SENT_MSG_CONTENT}
  mutation Send($content: String!, $fromDeviceId: ID!, $toAccountId: ID!) {
    sendSms(
      content: $content
      fromDeviceId: $fromDeviceId
      toAccountId: $toAccountId
    ) {
      ...SentMessage
    }
  }
`;
/**
 * Hook for send message mutation.
 *
 * TODO: typing of the cache is a little fishy here.
 */
export const useSend = (opts) => useMutation(SEND, opts);
