import React, { useState, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Layout, Grid } from 'antd';
import { ChatHome } from './ChatHome';
import { ChatList } from './ChatList';
import { ChatInfo, ChatMoreInfo } from './ChatInfo';
import { ClientInfoProvider } from '../ClientInfoProvider';
import { useIsAdmin } from '../state/hooks';
import { GoogleTranslateApiProvider } from '../Translate';
/**
 * Responsive side bar width values.
 */
const SIDE_BAR_WIDTH = {
    sm: '100%',
    md: 300,
};
/**
 * Container for the chat thread.
 */
export const ChatWindow = () => {
    const screens = Grid.useBreakpoint();
    const isAdmin = useIsAdmin();
    const [collapsed, setCollapsed] = useState(true);
    const [siderWidth, setSiderWidth] = useState(SIDE_BAR_WIDTH.md);
    const { accountId } = useParams();
    // Use a custom hook to determine whether the side bar should be collapsed.
    // We do this because the "Home" screen needs to show the chat list
    // regardless of the screen size (since it's the most important thing on the
    // screen at that point), but the "Chat" view can hide the chat list since
    // the chat is the most important thing in that case.
    useEffect(() => {
        if (!accountId) {
            setCollapsed(false);
            setSiderWidth(screens.md ? SIDE_BAR_WIDTH.md : SIDE_BAR_WIDTH.sm);
            return;
        }
        setCollapsed(!screens.md);
        setSiderWidth(SIDE_BAR_WIDTH.md);
    }, [accountId, screens.md]);
    return (React.createElement(GoogleTranslateApiProvider, null,
        React.createElement(Layout, null,
            React.createElement(Layout.Sider, { width: siderWidth, id: "nudge-chat-panel", collapsedWidth: "0", collapsed: collapsed },
                React.createElement(ChatList, null)),
            React.createElement(Layout, null,
                React.createElement(Layout.Content, { className: "nudge-body" }, !accountId ? (React.createElement(ChatHome, null)) : (React.createElement(ClientInfoProvider, { admin: isAdmin, accountId: accountId },
                    React.createElement(Layout, { id: "nudge-chat" },
                        React.createElement(Layout, { id: "nudge-chat-main" },
                            React.createElement("div", { id: "nudge-chat-info" },
                                React.createElement(ChatInfo, null)),
                            accountId ? React.createElement(Outlet, null) : null),
                        React.createElement(Layout.Sider, { width: 300, breakpoint: "lg", collapsedWidth: "0", id: "nudge-more-info" },
                            React.createElement(ChatMoreInfo, null))))))))));
};
