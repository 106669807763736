import React, { useContext, createContext } from 'react';
import { useClientInfo } from './net/query/ClientInfo.gql';
const ClientInfoContext = createContext({
    loading: false,
    error: null,
    clientInfo: null,
    fsm: null,
    featureAssignments: null,
});
export const useClientInfoCtx = () => {
    const ci = useContext(ClientInfoContext);
    return ci;
};
export const ClientInfoProvider = ({ children, accountId, admin, }) => {
    const { data, loading, error: netError, } = useClientInfo({
        pollInterval: 60000,
        variables: {
            id: accountId,
            idAsStr: accountId,
            admin,
        },
    });
    let clientInfo = null;
    let error = netError ? new Error(netError.message) : null;
    if (!error) {
        if (!loading) {
            if (data?.node?.__typename !== 'ClientAccount') {
                error = new Error('error loading client info');
                clientInfo = null;
            }
            else {
                clientInfo = data.node;
            }
        }
    }
    const d = {
        loading,
        error,
        clientInfo,
        fsm: data?.fsm || null,
        featureAssignments: data?.featureAssignments || null,
    };
    return (React.createElement(ClientInfoContext.Provider, { value: d }, children));
};
