import React, { useMemo } from 'react';
import { Spin, message } from 'antd';
export const LoadingContainer = (props) => {
    // Suppress errors while loading.
    const errorMessage = props.loading
        ? null
        : props.error
            ? props.error.message || `${props.error}`
            : null;
    useMemo(() => {
        if (errorMessage) {
            setTimeout(() => message.error(errorMessage), 0);
        }
    }, [errorMessage]);
    return (React.createElement("div", { style: props.style, className: "nudge-loading-container" },
        props.loading && React.createElement(Spin, null),
        !props.loading && props.children));
};
