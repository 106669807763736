import { gql, useMutation } from '@apollo/client';
/**
 * Mutation to update state of staff member.
 */
export const UPDATE_STAFF = gql `
  mutation UpdateStaff($id: ID!, $isActive: Boolean!, $roles: [Role!]!) {
    updateStaff(data: {id: $id, isActive: $isActive, roles: $roles}) {
      id
      isActive
      roles
    }
  }
`;
/**
 * Hook to update a staff person.
 */
export const useUpdateStaff = (opts) => useMutation(UPDATE_STAFF, opts);
