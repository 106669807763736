import { gql, useQuery } from '@apollo/client';
/**
 * Query to fetch the Nudge app config.
 */
export const APP_CONFIG = gql `
  query AppConfig {
    clientConfig {
      googleMapsKey
      googleTranslateKey
      oauth2 {
        issuer
        scopes
        authEndpoint
        tokenEndpoint
        webClient {
          clientId
          redirectUrl
        }
        state
        accessType
      }
      style {
        login {
          logo
          bgColor
          textColor
          btnColor
          btnTextColor
          signInText
        }
        accentColor
      }
    }
  }
`;
/**
 * Hook to fetch app config.
 */
export const useAppConfig = (opts) => useQuery(APP_CONFIG, opts);
