import { ApolloClient, createHttpLink, ApolloLink, } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { allClientsSchemaExtension } from './query/AllClients.gql';
import { allStaffSchemaExtension } from './query/AllStaff.gql';
import { chatSchemaExtension } from './query/Chat.gql';
import { unreadCountSchemaExtension } from './query/Notification.gql';
import { followUpsSchemaExtension } from './query/FollowUpThreads.gql';
import { initCache } from './cache/init';
// Expose things for debugging.
export const _debug = {
    cache: null,
    client: null,
};
/**
 * Get the token as a string to use in the Authorization header.
 *
 * If the access token is missing this returns undefined.
 */
const authTokenString = (tokenInfo) => {
    if (!tokenInfo.accessToken) {
        return undefined;
    }
    return `${tokenInfo.accessTokenType} ${tokenInfo.accessToken}`;
};
/**
 * Inspect error to catch authentication issues.
 */
const tokenExpiredError = (e) => {
    if (/^(Invalid access token|No access token)$/i.test(e.message)) {
        return true;
    }
    return false;
};
/*
/**
 * Get a configured instance of the Apollo Client.
 *
 * This client connects to the right graphql endpoint using the correct
 * authentication and handles errors.
 */
export const configureApolloClient = (opts) => {
    // Reference to a refresh promise so that only one happens at a time.
    let _refreshTokenRequestSingleton = null;
    // Get the base URI for making requests.
    const httpLink = createHttpLink({
        uri: opts.getBaseUri,
    });
    // Add auth info to the request if it's available.
    const authLink = setContext(async (_, { headers }) => {
        if (_refreshTokenRequestSingleton) {
            await _refreshTokenRequestSingleton;
        }
        const tokenInfo = opts.getAuthTokenInfo();
        return {
            headers: {
                ...headers,
                authorization: authTokenString(tokenInfo),
            },
        };
    });
    // Retry requests periodically.
    const retryLink = new RetryLink({
        delay: {
            initial: 300,
            max: 10000,
            jitter: true,
        },
        attempts: {
            max: 5,
            retryIf: (error) => !!error,
        },
    });
    // Handle errors from GraphQL.
    const errorLink = onError(({ graphQLErrors, networkError, operation, response, forward }) => {
        if (graphQLErrors) {
            for (const e of graphQLErrors) {
                // Detect authentication errors.
                // TODO(jnu): handle insufficient permission errors separately
                if (tokenExpiredError(e)) {
                    // Refresh the token and retry the operation.
                    if (!_refreshTokenRequestSingleton) {
                        _refreshTokenRequestSingleton = opts
                            .refreshAuthToken()
                            .catch((e2) => opts.handleAuthError(e2))
                            .finally(() => {
                            _refreshTokenRequestSingleton = null;
                        });
                    }
                    return forward(operation);
                }
            }
            // Filter errors based on custom handler
            if (response) {
                response.errors = response?.errors?.filter((e) => !opts.handleGraphqlError(e, operation));
                if (response.errors?.length === 0) {
                    response.errors = undefined;
                }
            }
        }
        if (networkError) {
            opts.handleNetworkError(networkError);
        }
    });
    // Pass the cache a functor that lets it get a reference to the client.
    const cache = initCache(() => apolloClient);
    const apolloClient = new ApolloClient({
        link: ApolloLink.from([errorLink, retryLink, authLink, httpLink]),
        typeDefs: [
            allClientsSchemaExtension,
            allStaffSchemaExtension,
            chatSchemaExtension,
            unreadCountSchemaExtension,
            followUpsSchemaExtension,
        ].join('\n'),
        cache,
    });
    // Expose stuff for debugging
    _debug.cache = cache;
    _debug.client = apolloClient;
    // Ta-da! a quite fancy Apollo Client instance.
    return apolloClient;
};
