import { gql, useQuery, makeVar } from '@apollo/client';
import { MESSAGE_CONTENT } from './fragments/MessageContent.gql';
/**
 * Query to fetch conversation thread history for user.
 */
export const CHAT = gql `
  ${MESSAGE_CONTENT}
  query Chat($id: ID!, $offset: ID, $limit: Int!) {
    getMessageHistory(clientAccountID: $id, offset: $offset, limit: $limit) {
      ...MessageContent
    }
    hasMoreMessages(id: $id) @client
  }
`;
/**
 * Hook to fetch a single chat.
 */
export const useChat = (opts) => useQuery(CHAT, opts);
/**
 * Local schema extension to support querying hasMoreMessages variable.
 */
export const chatSchemaExtension = gql `
  extend type Query {
    hasMoreMessages(id: ID!): Boolean!
  }
`;
/**
 * Reactive variable to track local state about whether there are more
 * messages available in a chat thread.
 */
export const hasMoreMessages = makeVar(new Map());
