import { useStore, useDispatch, useSelector, } from 'react-redux';
import { Role } from '../net/types/graphql';
/**
 * Typed version of useStore.
 */
export const useAppStore = () => useStore();
/**
 * Typed version of useDispatch.
 */
export const useAppDispatch = useDispatch;
/**
 * Typed version of useSelector.
 */
export const useAppSelector = useSelector;
/**
 * Check if current user has ADMIN permission.
 */
export const useIsAdmin = () => useAppSelector(({ auth }) => (auth.roles || []).includes(Role.Admin));
/**
 * Check if current user has STAFF permission.
 */
export const useIsStaff = () => useAppSelector(({ auth }) => (auth.roles || []).includes(Role.Staff));
