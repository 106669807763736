import { getId } from './id';
import { sortedInsert } from '../../util/sortedInsert';
/**
 * Insert a new message into a message thread in sorted order.
 */
export const insertMessage = (history, msg, opts) => {
    sortedInsert(history, msg, (m) => {
        if (opts.readField) {
            const s = opts.readField('seq', m);
            if (s !== undefined) {
                return s;
            }
        }
        if (Object.hasOwn(m, 'seq')) {
            return m.seq;
        }
        // Fallback on parsing the sequence from the ID itself.
        // This is a hack, but it's reliable.
        // There is a bug in the Apollo cache where sometimes references are not
        // readable during a merge operation, so this fallback is important.
        if (Object.hasOwn(m, '__ref')) {
            const mId = m.__ref.split(':')[1];
            return parseInt(atob(mId).split(':')[1], 10);
        }
        throw new Error(`Unknown sequence for message ${JSON.stringify(m)}`);
    }, false, (existing, incoming) => opts.mergeObjects
        ? opts.mergeObjects(existing, incoming)
        : { ...existing, ...incoming });
    return history;
};
/**
 * Get the cache ID for a message out of an ID string.
 *
 * If the ID string is already a cache ID, it is returned directly.
 *
 * Otherwise, if the normal GQL message ID is passed, this will return the
 * cache ID for the message.
 */
export const getMessageCacheId = (msgIdOrCacheId) => {
    if (msgIdOrCacheId.startsWith('Message:')) {
        return msgIdOrCacheId;
    }
    return `Message:${msgIdOrCacheId}`;
};
/**
 * Get the message history cache ID for the given client.
 */
export const getMessageThreadId = (clientId) => {
    // Very ugly derivation of the field name, since the
    // apollo client doesn't currently support passing args
    // directly.
    return `getMessageHistory:${JSON.stringify({
        clientAccountID: clientId,
    })}`;
};
/**
 * Merge two lists of messages in the cache.
 */
export const mergeMessageLists = (existing, incoming, opts) => {
    // Create a map from ID to existing messages in order to dedupe
    const extMap = (existing || []).reduce((m, cur) => {
        m.set(getId(opts, cur), opts.toReference(cur));
        return m;
    }, new Map());
    // Dedupe incoming by setting them in the map
    for (const inc of incoming) {
        extMap.set(getId(opts, inc), opts.toReference(inc));
    }
    // Read values back in sorted order out of the deduped map.
    const merged = [];
    for (const msg of extMap) {
        insertMessage(merged, msg[1], opts);
    }
    return merged;
};
