import React from 'react';
import { Button, Image } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
/**
 * Trigger a download of the given URL.
 */
const download = (url, name) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};
/**
 * Render an MMS message.
 */
export const MediaMessage = ({ url, mimeType, name }) => {
    switch (mimeType) {
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/gif':
        case 'image/png':
            return React.createElement(Image, { src: url, width: 200 });
        default:
            // If we can't display the file directly, render a download button.
            // TODO: We can support more media types if there's demand.
            return (React.createElement(Button, { type: "primary", onClick: () => download(url, name), shape: "round", icon: React.createElement(DownloadOutlined, null) }, "Download media attachment"));
    }
};
