/**
 * Coerce potential error to an actual Error.
 */
export const asError = (e) => {
    if (e instanceof Error) {
        return e;
    }
    return new Error(`${e}`);
};
/**
 * Get the error message from an error.
 */
export const errorMessage = (e) => {
    if (e instanceof Error) {
        return e.message;
    }
    return `${e}`;
};
