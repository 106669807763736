import { gql, useQuery } from '@apollo/client';
/**
 * Query to geocode an address.
 */
export const GEOCODE = gql `
  query Geocode($address: String!) {
    geocode(address: $address) {
      lat
      lng
      address
    }
  }
`;
/**
 * Hook to geocode an address.
 */
export const useGeocode = (opts) => useQuery(GEOCODE, opts);
/**
 * Run geocoding request on demand.
 */
export const geocode = (apolloClient, address) => {
    return apolloClient.query({
        query: GEOCODE,
        variables: { address },
        fetchPolicy: 'cache-first',
    });
};
