import { gql } from '@apollo/client';
/**
 * Fragment to pull the content and metadata of a single message.
 */
export const MESSAGE_CONTENT = gql `
  fragment MessageContent on Message {
    id
    content
    seq
    sent
    status
    errorCode
    meta {
      tags
    }
    mediaUrls {
      uri
      contentType
    }
    destination {
      account {
        id
        fullName
      }
    }
    source {
      account {
        id
        fullName
        ... on AssociateAccount {
          client {
            id
            fullName
          }
        }
      }
    }
  }
`;
