import React from 'react';
import { Card, Layout } from 'antd';
import { VersionInfo } from './VersionInfo';
import { Disclaimer } from './Disclaimer';
import { About } from './About';
/**
 * Help / about page.
 */
export const Help = () => {
    return (React.createElement(Layout, null,
        React.createElement(Layout.Content, { className: "nudge-body", id: "nudge-help" },
            React.createElement(Card, { title: "About Nudge" },
                React.createElement(About, null)),
            React.createElement(Card, { title: "Nudge Info" },
                React.createElement(VersionInfo, null)),
            React.createElement(Card, { title: "Disclaimers" },
                React.createElement(Disclaimer, null)))));
};
