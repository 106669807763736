import React from 'react';
import { Card, Layout, Typography } from 'antd';
import { useIsAdmin } from '../state/hooks';
import { ManageDevices } from './ManageDevices';
import { ManageStaff } from './ManageStaff';
/**
 * General settings page.
 */
export const Settings = () => {
    const isAdmin = useIsAdmin();
    return (React.createElement(Layout, null,
        React.createElement(Layout.Content, { className: "nudge-body", id: "nudge-settings" },
            React.createElement(Card, { title: "My Devices" },
                React.createElement(Typography.Paragraph, null, "This is a list of devices you have logged into Nudge with. If you have logged in on multiple browser sessions, or cleared your browser's storage at any time, you may see multiple entries here."),
                React.createElement(Typography.Paragraph, null, "Generally you don't have to worry about the values here."),
                React.createElement(ManageDevices, null)),
            isAdmin ? (React.createElement(Card, { title: "Users" },
                React.createElement(Typography.Paragraph, null, "This is a list of all staff members we know about in your organization. You can use this table to manage their access to Nudge."),
                React.createElement(Typography.Paragraph, null,
                    "Turn on the ",
                    React.createElement("strong", null, "Admin"),
                    " toggle for users who should have access to administrative features, such as this table."),
                React.createElement(Typography.Paragraph, null,
                    "Turn on the ",
                    React.createElement("strong", null, "Staff"),
                    " toggle for users who should have access to Nudge.",
                    React.createElement("em", null,
                        "Users should have the ",
                        React.createElement("strong", null, "Staff"),
                        " toggle",
                        ' ',
                        React.createElement("strong", null, "on"),
                        " to be able to see their own clients in Nudge!")),
                React.createElement(Typography.Paragraph, null,
                    "Turn off the ",
                    React.createElement("strong", null, "Active"),
                    " toggle to disable access to Nudge entirely for a user."),
                React.createElement(Typography.Paragraph, null,
                    "You can use the \"filter\" button in the ",
                    React.createElement("code", null, "Name"),
                    " column to search for specific users."),
                React.createElement(ManageStaff, null))) : null)));
};
