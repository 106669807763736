import { gql, useQuery } from '@apollo/client';
export const FCM = gql `
  query Fcm {
    fcmConfig {
      apiKey
      authDomain
      databaseURL
      projectId
      storageBucket
      messagingSenderId
      appId
      messagingPublicKey
    }
  }
`;
export const useFcm = (opts) => useQuery(FCM, opts);
