import { hasMoreStaff } from '../../query/AllStaff.gql';
/**
 * Field policy for merging staff user result pages into the cache.
 */
export const staff = {
    keyArgs: [],
    // Merge paginated queries into the cached data directly.
    // See: https://www.apollographql.com/docs/react/pagination/core-api/#defining-a-field-policy
    merge(existing = [], incoming, opts) {
        const offset = opts.args?.offset || 0;
        const limit = opts.args?.limit || 0;
        const merged = existing.slice();
        // If the query returned less data than requested, mark that
        // it is finished producing.
        if (incoming.length < limit) {
            hasMoreStaff(false);
        }
        // Merge incoming data using the precise offset in case query
        // is loaded multiple times, or out of order.
        for (let i = 0; i < incoming.length; i++) {
            merged[offset + i] = incoming[i];
        }
        return merged;
    },
};
