import { unreadMessages as unreadMessagesVar } from '../../query/AllClients.gql';
import * as log from '../../logger';
/**
 * Implementation of unread message count.
 */
export const unreadMessages = {
    read(_, opts) {
        const clientId = opts.readField('id');
        if (!clientId) {
            log.error("Can't find ID for unread messages field");
            return 0;
        }
        const unreads = unreadMessagesVar();
        const clientUnreads = unreads.get(clientId);
        return clientUnreads ? clientUnreads.size : 0;
    },
};
