import { getMessageThreadId } from '../message';
/**
 * The messageHistory field exists on ClientAccount and AssociateAccount.
 *
 * Unfortunately this is named similarly to the root query `getMessageHistory`.
 * This field delegates to the other `getMessageHistory`, which supports
 * pagination. This field is deprecated!
 */
export const messageHistory = {
    keyArgs: [],
    // Read the message history from the paginated root query.
    read(_, opts) {
        const id = opts.readField('id');
        const cacheId = getMessageThreadId(id);
        return opts.readField(cacheId, opts.toReference('ROOT_QUERY'));
    },
};
