import React from 'react';
import { Link, useNavigate, } from 'react-router-dom';
/**
 * Clean up links to make sure they're safely url-encoded.
 */
export const sanitizeLink = (path) => {
    // Don't try to sanitize non-string URLs, though we could in the future.
    if (typeof path !== 'string') {
        return path;
    }
    // Don't try to mess with non-relative URLs.
    if (!path.startsWith('/')) {
        return path;
    }
    // Split up the path, we expect something like `/foo/bar?baz=bop
    // Split at the first `?`
    const queryIdx = path.indexOf('?');
    const q = queryIdx > -1 ? path.slice(queryIdx + 1) : '';
    const basePath = queryIdx > -1 ? path.slice(0, queryIdx) : path;
    // Format each segment of the path like `/foo/bar/baz`
    const pathParts = basePath.split('/');
    for (let i = 0; i < pathParts.length; i++) {
        pathParts[i] = encodeURIComponent(pathParts[i]);
    }
    // Handle search params like `?foo&bar=baz`
    const queryPairs = q.split('&');
    for (let i = 0; i < queryPairs.length; i++) {
        // Split at the first `=`
        const eqIdx = queryPairs[i].indexOf('=');
        // handle boolean flags
        if (eqIdx === -1) {
            queryPairs[i] = encodeURIComponent(queryPairs[i]);
            continue;
        }
        const kv = [queryPairs[i].slice(0, eqIdx), queryPairs[i].slice(eqIdx + 1)];
        queryPairs[i] = kv.map((x) => encodeURIComponent(x)).join('=');
    }
    // Rejoin cleaned components.
    return pathParts.join('/') + (q ? `?${queryPairs.join('&')}` : '');
};
/**
 * Link that cleans up the `to` URL automatically.
 */
export const SafeLink = (props) => {
    return React.createElement(Link, { ...props, to: sanitizeLink(props.to) });
};
/**
 * Hook to navigate, automatically cleaning up the `to` URL.
 */
export const useSafeNavigate = () => {
    const nav = useNavigate();
    return (to, opts) => nav(sanitizeLink(to), opts);
};
