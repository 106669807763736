import { gql, useQuery } from '@apollo/client';
/**
 * Query to fetch notification count.
 */
export const UNREAD_NOTIFS = gql `
  query UnreadNotifs($accountId: ID!) {
    node(id: $accountId) {
      ... on StaffAccount {
        notifications {
          payload
        }
        unreadCount @client
      }
    }
  }
`;
/**
 * React hook for notification count.
 */
export const useUnreadNotifs = (opts) => useQuery(UNREAD_NOTIFS, opts);
/**
 * Extension to get a live count of unread notifications.
 */
export const unreadCountSchemaExtension = gql `
  extend type StaffAccount {
    unreadCount: Int!
  }
`;
