import { gql, useQuery } from '@apollo/client';
import { MESSAGE_CONTENT } from './fragments/MessageContent.gql';
/**
 * Query to fetch list of responses we were not sure how to handle in the
 * given time range.
 */
export const NON_STANDARD_REPLIES = gql `
  ${MESSAGE_CONTENT}

  query NonStandardReplies($from: Time!, $to: Time!) {
    nonStandardReplies(from: $from, to: $to) {
      ...MessageContent
    }
  }
`;
/**
 * Hook to run the non-standard replies query.
 */
export const useNonStandardReplies = (opts) => useQuery(NON_STANDARD_REPLIES, opts);
