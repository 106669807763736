import { gql, useMutation, } from '@apollo/client';
import { updateOnCancelRide } from '../cache/update/cancelRide';
/**
 * Mutation for canceling a ride.
 */
export const CANCEL_RIDE = gql `
  mutation CancelRide($id: ID!) {
    cancelRide(rideId: $id)
  }
`;
/**
 * Hook to cancel a ride.
 */
export const useCancelRide = (opts) => useMutation(CANCEL_RIDE, {
    update: updateOnCancelRide,
    ...opts,
});
