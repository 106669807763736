import { hasMoreClients as hasMoreClientsVar } from '../../query/AllClients.gql';
/**
 * Implementation of local field that indicates whether clients list query has
 * more result pages available.
 */
export const hasMoreClients = {
    read() {
        return hasMoreClientsVar();
    },
};
