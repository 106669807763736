import React, { useState, useEffect } from 'react';
import { Button, Select } from 'antd';
/**
 * Get options to display in the combo boxes.
 */
const gatherCombos = (states, stateParts) => {
    const combos = [[]];
    for (const row of states) {
        // Every first-combo option is always visible.
        for (let j = 0; j < row.length; j++) {
            if (j === 0 || row[j - 1].includes(stateParts[j - 1])) {
                for (const opt of row[j]) {
                    combos[j] = combos[j] || [];
                    combos[j].push(opt);
                }
            }
        }
    }
    return combos;
};
/**
 * Serialize a state slug.
 */
const ser = (stateParts) => stateParts.join('|');
/**
 * Deserialize a state slug.
 */
const des = (state) => (state ? state.split('|') : []);
/**
 * Combo box for setting the client's FSM state.
 *
 * The client is sometimes in multiple states at once, depending on which
 * machine they are in and where. For example, a user might start in a single
 * state, then enter two parallel machines in which they are in two states
 * simultaneously, and then return to a single state.
 *
 * States are serialized with `|`, so for example if the client is in state
 * `a` and `b` simultaneously, the state returned by the server is `a|b`.
 * For display in the UI, we use a variable number of combo boxes. The first
 * box will contain options for all of the top-level states. Additional combo
 * boxes will be rendered only when the client is eligible to be in parallel
 * states. Note that when a client is _eligible_ to be in parallel states,
 * they _must_ have one state value for each parallel machine; they are not
 * optional states.
 *
 * The `onUpdate` method gets passed a serialized parallel state slug whenever
 * a combo box is updated.
 */
export const StateEditor = ({ states, current, onUpdate, error, loading, }) => {
    const [stateParts, setStateParts] = useState(des(current));
    const combos = gatherCombos(states, stateParts);
    useEffect(() => {
        setStateParts(des(current));
    }, [current]);
    const update = (nestedStates, value, col) => {
        const newIdx = nestedStates.findIndex((r) => r[col]?.includes(value));
        const newRow = nestedStates[newIdx];
        const newState = [...stateParts].slice(0, newRow.length);
        newState[col] = value;
        newState.slice(0, newState.length);
        for (let i = 0; i < newState.length; i++) {
            if (i !== col) {
                if (!newRow[i].includes(newState[i])) {
                    newState[i] = newRow[i][0];
                }
            }
        }
        setStateParts(newState.slice());
    };
    return (React.createElement("div", { className: "state-editor" },
        combos.map((opts, i) => (React.createElement("div", { key: i, className: "state-editor__combo" },
            React.createElement(Select, { style: { width: 180, textAlign: 'center' }, value: stateParts[i] || opts[0], disabled: !!error, loading: loading, onSelect: (value) => update(states, value, i) }, opts.map((opt) => (React.createElement(Select.Option, { key: opt, value: opt }, opt))))))),
        React.createElement(Button, { onClick: () => onUpdate(ser(stateParts)), loading: loading, disabled: ser(stateParts) === current }, "Save")));
};
