import { gql, useMutation } from '@apollo/client';
/**
 * Mutation to register this web client with the server.
 */
export const REGISTER_DEVICE = gql `
  mutation RegisterDevice(
    $deviceId: ID!
    $token: String!
    $ownerId: ID!
    $deviceType: DeviceType!
  ) {
    registerDevice(
      input: {
        id: $deviceId
        ownerId: $ownerId
        type: $deviceType
        token: $token
      }
    ) {
      id
      receiveUnknownMessages
      owner {
        fullName
      }
    }
  }
`;
/**
 * Hook to register device.
 */
export const useRegisterDevice = (opts) => useMutation(REGISTER_DEVICE, opts);
