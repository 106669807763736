import { unreadMessages as unreadMessagesVar } from '../../query/AllClients.gql';
/**
 * Get unread notification count on StaffAccount.
 */
export const unreadCount = {
    read() {
        let tally = 0;
        for (const n of unreadMessagesVar().values()) {
            tally += n.size;
        }
        return tally;
    },
};
