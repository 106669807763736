import { gql, useQuery, makeVar } from '@apollo/client';
import { CLIENT_PROFILE } from './fragments/ClientProfile.gql';
/**
 * Query to fetch all clients.
 */
export const ALL_CLIENTS = gql `
  ${CLIENT_PROFILE}

  query AllClients(
    $limit: Int!
    $offset: Int!
    $search: String
    $staffId: ID
    $mine: Boolean
    $interacted: Boolean
  ) {
    clients(
      limit: $limit
      offset: $offset
      search: $search
      staffId: $staffId
      mine: $mine
      interacted: $interacted
    ) {
      ...ClientProfile
    }
    hasMoreClients @client
  }
`;
/**
 * Hook to fetch all clients.
 */
export const useAllClients = (opts) => useQuery(ALL_CLIENTS, opts);
/**
 * Local schema extension to support querying hasMoreClients variable.
 */
export const allClientsSchemaExtension = gql `
  extend type Query {
    hasMoreClients: Boolean!
  }

  extend type ClientAccount {
    unreadMessages: Int!
  }
`;
/**
 * Reactive variable to track whether query has more data.
 */
export const hasMoreClients = makeVar(true);
/**
 * Reactive variable tracking list of unread messages.
 *
 * Data type is: Map<ClientId, Map<MsgId, EventId>>
 */
export const unreadMessages = makeVar(new Map());
