import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Grid, Menu, Layout, Image, Typography } from 'antd';
import { QuestionCircleOutlined, CommentOutlined, AuditOutlined, SettingOutlined, LogoutOutlined, MenuOutlined, } from '@ant-design/icons';
import { useIsAdmin } from './state/hooks';
import { useSafeNavigate as useNavigate, SafeLink as Link, } from './util/safeNavigate';
import { staticPath } from './util/static';
import './App.css';
/**
 * Keys for the nav menu.
 */
const NAV = {
    Chat: {
        key: 'chat',
        url: '/chat',
    },
    Audit: {
        key: 'audit',
        url: '/audit',
    },
    Settings: {
        key: 'settings',
        url: '/settings',
    },
    Help: {
        key: 'help',
        url: '/help',
    },
};
/**
 * Check which subpage of the site is active based on the URL.
 */
const getActiveNavKey = () => {
    const location = useLocation();
    const path = location.pathname;
    for (const { key, url } of Object.values(NAV)) {
        if (path.startsWith(url)) {
            return key;
        }
    }
    return '';
};
/**
 * Frame of the logged-in app (header + body).
 */
export const App = () => {
    const screens = Grid.useBreakpoint();
    const navigate = useNavigate();
    const currentNavKey = getActiveNavKey();
    const isAdmin = useIsAdmin();
    return (React.createElement(Layout, { id: "nudge-root" },
        React.createElement(Layout.Header, { id: "nudge-header", className: !screens.md ? '-smol' : '' },
            React.createElement(Link, { to: "/", id: "nudge-banner-logo", className: "nudge-banner-tab" },
                React.createElement(Image, { src: staticPath('nudge.png'), preview: false, width: 25 }),
                screens.sm ? (React.createElement(Typography.Title, { level: 4 },
                    "Nudge ",
                    isAdmin ? React.createElement("span", { className: "-sub-head" }, "Admin") : null)) : null),
            React.createElement(Menu, { id: "nudge-nav", overflowedIndicator: React.createElement(MenuOutlined, null), selectedKeys: [currentNavKey], mode: "horizontal", items: [
                    {
                        key: NAV.Chat.key,
                        icon: React.createElement(CommentOutlined, null),
                        onClick: () => navigate('/chat'),
                        label: 'Chats',
                    },
                    {
                        key: NAV.Audit.key,
                        icon: React.createElement(AuditOutlined, null),
                        onClick: () => navigate('/audit/follow-up'),
                        label: 'Audit',
                    },
                    {
                        key: NAV.Settings.key,
                        icon: React.createElement(SettingOutlined, null),
                        onClick: () => navigate(NAV.Settings.url),
                        label: 'Settings',
                    },
                    {
                        key: 'help',
                        icon: React.createElement(QuestionCircleOutlined, null),
                        onClick: () => navigate('/help'),
                        label: 'Help',
                    },
                    {
                        key: 'logout',
                        icon: React.createElement(LogoutOutlined, null),
                        onClick: () => navigate('/logout'),
                        label: 'Logout',
                    },
                ] })),
        React.createElement(Outlet, null)));
};
