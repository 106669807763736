import React, { useEffect, useState } from 'react';
import { Checkbox, Tooltip, Input, List, Skeleton, Typography, Badge, Dropdown, } from 'antd';
import { ExclamationCircleTwoTone, SubnodeOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs from 'dayjs';
import { useIsAdmin, useAppSelector } from '../state/hooks';
import { useSafeNavigate as useNavigate } from '../util/safeNavigate';
import { useAllClients } from '../net/query/AllClients.gql';
import { useFsmStates } from '../net/query/FsmStates.gql';
/**
 * How many chat threads to load in a single query.
 *
 * New batches will be loaded as user scrolls.
 */
const CHATS_QUERY_BATCH_SIZE = 20;
/**
 * Interval to re-render chat list (to update timestamps), in milliseconds.
 */
const RERENDER_INTERVAL = 60 * 1000;
/**
 * Abstraction over clients queries that calls the correct query regardless
 * of whether this is an admin or a regular user, and returns results in a
 * consistent format.
 */
const useClients = (search, interacted) => {
    const id = useAppSelector(({ auth }) => auth.userId || '');
    const isAdmin = useIsAdmin();
    return useAllClients({
        errorPolicy: 'all',
        pollInterval: RERENDER_INTERVAL,
        variables: {
            limit: CHATS_QUERY_BATCH_SIZE,
            offset: 0,
            search: search || undefined,
            mine: !isAdmin,
            interacted,
            staffId: id,
        },
    });
};
/**
 * List of chat threads ordered by most recent with infinite scrolling.
 */
export const ChatList = () => {
    const isAdmin = useIsAdmin();
    const { accountId } = useParams();
    const rerender = useState({})[1];
    const fsmData = useFsmStates();
    const [search, setSearch] = useState('');
    const [interactedFilter, setInteractedFilter] = useState(false);
    const navigate = useNavigate();
    const { data, refetch, fetchMore, loading } = useClients(search, interactedFilter);
    const fsmStates = (fsmData.data?.fsm.meta.states || [])
        .slice()
        .sort((a, b) => a.localeCompare(b));
    const fsmStatesItems = [
        { label: '<none>', key: '' },
        ...fsmStates.map((state) => ({
            label: state,
            key: state,
        })),
    ];
    const clickFsmState = ({ key }) => {
        setSearch(`state:${key}`);
    };
    // Delayed execution of search query
    const refetchForSearch = debounce(() => refetch({
        limit: CHATS_QUERY_BATCH_SIZE,
        offset: 0,
        search: search || undefined,
        mine: !isAdmin,
        interacted: interactedFilter,
    }), 150);
    // Schedule search query when search field changes
    useEffect(() => {
        // Don't run expensive queries for short searches
        if (search && search.length < 3) {
            return;
        }
        refetchForSearch();
    }, [search]);
    // Schedule refetch when interacted filter changes
    useEffect(() => {
        refetchForSearch();
    }, [interactedFilter]);
    // Re-render the chat list periodically to update the timestamps
    useEffect(() => {
        const iv = setInterval(() => rerender({}), RERENDER_INTERVAL);
        return () => {
            clearInterval(iv);
        };
    }, []);
    // Compute offset for fetching next batch of clients.
    const nextBatchOffset = (data?.clients || []).length;
    // Some clients might be null if an error occurred on the backend when trying
    // to load them. Filter them out so that rendering doesn't break.
    const clients = (data?.clients || [])
        .filter((c) => !!c)
        .sort((a, b) => {
        // Sort by most recent messages
        const aSent = a.lastMessage?.sent;
        const bSent = b.lastMessage?.sent;
        if (!aSent && !bSent) {
            return -1;
        }
        else if (!aSent) {
            return 1;
        }
        else if (!bSent) {
            return -1;
        }
        return aSent > bSent ? -1 : 1;
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "nudge-chat-search-container" },
            React.createElement(Input.Search, { className: "nudge-chat-search", onChange: (e) => setSearch(e.target.value), value: search, variant: "borderless", loading: loading, onSearch: refetchForSearch, placeholder: isAdmin ? 'Search clients by name or ID' : 'Search clients by name' }),
            isAdmin && (React.createElement(Dropdown, { menu: { items: fsmStatesItems, onClick: clickFsmState } },
                React.createElement("a", { className: "ant-dropdown-link", onClick: (e) => e.preventDefault() },
                    React.createElement(SubnodeOutlined, { style: {
                            fontSize: '1.25rem',
                            paddingRight: '0.5rem',
                            paddingLeft: '0.25rem',
                        } }))))),
        isAdmin && (React.createElement("div", { id: "nudge-chat-filter-container" },
            React.createElement(Checkbox, { onChange: (e) => setInteractedFilter(e.target.checked) }, "Hide clients I haven't messaged"))),
        React.createElement("div", { id: "nudge-chat-list-container" },
            React.createElement(InfiniteScroll, { scrollableTarget: "nudge-chat-list-container", dataLength: clients.length, next: () => fetchMore({
                    variables: {
                        offset: nextBatchOffset,
                    },
                }), hasMore: data?.hasMoreClients || false, loader: React.createElement(Skeleton, { paragraph: { rows: 1 }, active: true }), pullDownToRefresh: true, refreshFunction: refetch },
                React.createElement(List, { dataSource: clients, renderItem: (item) => (React.createElement(List.Item, { className: `nudge-chat-list-item ${accountId === item.id ? '-active' : '-inactive'} ${item.unreadMessages > 0 ? '-unread' : ''} ${item.isActive ? '' : '-disabled'}`, onClick: () => navigate(`/chat/${item.id}`), actions: item.isActive
                            ? undefined
                            : [
                                React.createElement(Tooltip, { title: "Failed to fetch information about this person." },
                                    React.createElement(ExclamationCircleTwoTone, { twoToneColor: "#ff4d4f" })),
                            ], extra: React.createElement(Typography.Text, { type: "secondary", title: item.lastMessage?.sent
                                ? dayjs(item.lastMessage.sent).format('LLLL')
                                : undefined }, item.lastMessage?.sent
                            ? dayjs(item.lastMessage.sent).fromNow()
                            : null) },
                        React.createElement(List.Item.Meta, { avatar: item.unreadMessages > 0 && accountId !== item.id ? (React.createElement(Badge, { size: "small", count: item.unreadMessages })) : undefined, title: item.fullName || '<Unknown>', description: item.lastMessage?.content || '' }))) })))));
};
