import { gql, useMutation } from '@apollo/client';
/**
 * Toggle the "receive unknown messages" option.
 */
export const TOGGLE_UNKNOWN_MSG_SUB = gql `
  mutation ToggleUnknownMsgSub($deviceId: ID!, $toggle: Boolean!) {
    toggleUnknownMessageReceive(deviceId: $deviceId, receiveOrNah: $toggle) {
      id
      receiveUnknownMessages
    }
  }
`;
/**
 * Hook to toggle the "receive unknown messages" option.
 */
export const useToggleUnknownMsgSub = (opts) => useMutation(TOGGLE_UNKNOWN_MSG_SUB, opts);
