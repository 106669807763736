import { getQueryParam } from './env';
import * as log from './log';
/**
 * Check if sentry debugging should be enabled.
 */
const verboseSentry = () => {
    const params = getQueryParam('debugSentry');
    return params[1];
};
/**
 * Get the DSN for logging to sentry if available.
 *
 * If credentials are not provided, this returns an empty string, which will
 * disable Sentry logging.
 */
const dsn = () => {
    if (SENTRY_DISABLE === '1') {
        log.info('Sentry logging is disabled.');
        return '';
    }
    if (!SENTRY_PK || !SENTRY_ORG || !SENTRY_ID) {
        log.warn('Sentry credentials not provided, so it will not be enabled.');
        return '';
    }
    return `https://${SENTRY_PK}@${SENTRY_ORG}.ingest.sentry.io/${SENTRY_ID}`;
};
/**
 * Configuration for Sentry shared between main UI thread and ServiceWorker.
 *
 * Most of these options are linked at build time.
 */
export const SentryConfig = {
    dsn: dsn(),
    environment: process.env.NODE_ENV,
    debug: verboseSentry(),
    release: NUDGE_VERSION,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
};
