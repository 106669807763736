import React, { useState, useEffect } from 'react';
import { message, Typography } from 'antd';
import { useAppSelector } from '../state/hooks';
/**
 * Copy version text to the clipboard.
 */
const copyVersion = async (s) => {
    try {
        await navigator.clipboard.writeText(s);
        message.success('Copied Nudge version info to clipboard!');
    }
    catch (e) {
        message.error(`Failed to copy Nudge version: ${e}`);
    }
};
/**
 * Find info about the Nudge API.
 */
const fetchApiInfo = async (baseUrl) => {
    const r = await fetch(`${baseUrl}info`);
    const d = await r.json();
    return d;
};
/**
 * Hook to find Nudge API info.
 */
const useApiInfo = () => {
    const [error, setError] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [apiInfo, setApiInfo] = useState(null);
    const gqlUrl = useAppSelector(({ baseUrl }) => baseUrl.value);
    const apiUrl = useAppSelector(({ baseUrl }) => baseUrl.root || '/');
    useEffect(() => {
        let canceled = false;
        setFetching(true);
        setApiInfo(null);
        setError(null);
        fetchApiInfo(apiUrl)
            .then((info) => {
            if (canceled) {
                return;
            }
            setFetching(false);
            setError(null);
            setApiInfo(info);
        })
            .catch((e) => {
            if (canceled) {
                return;
            }
            setFetching(false);
            setError(e);
        });
        return () => {
            canceled = true;
        };
    }, [apiUrl]);
    return {
        fetching,
        info: apiInfo,
        url: gqlUrl,
        error,
    };
};
/**
 * Print the version information.
 */
export const VersionInfo = () => {
    const api = useApiInfo();
    const data = [
        ['Web Version', NUDGE_VERSION],
        ['Web Release Date', NUDGE_RELEASE_DATE],
        ['API URL', api.url],
        ['API Core Version', api.info?.coreVersion],
        ['API Plugin Version', api.info?.pluginVersion],
        ['API Status', api.info?.status],
    ];
    const s = data.map(([label, value]) => `${label}: ${value}`).join('\n');
    return (React.createElement("div", { id: "nudge-version", onClick: () => copyVersion(s) }, data.map(([label, value]) => (React.createElement("div", { className: "-v-extra", key: label },
        React.createElement(Typography.Text, { className: "-v-label" }, label),
        React.createElement(Typography.Text, { type: "secondary", className: "-v-value" }, value))))));
};
