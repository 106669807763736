import { gql } from '@apollo/client';
/**
 * Fragment to pull client info.
 */
export const CLIENT_PROFILE = gql `
  fragment ClientProfile on ClientAccount {
    id
    fullName
    isActive
    unreadMessages @client
    lastMessage {
      sent
      seq
      content
      source {
        account {
          id
        }
      }
    }
  }
`;
