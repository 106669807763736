import { gql } from '@apollo/client';
import { MESSAGE_CONTENT } from './fragments/MessageContent.gql';
/**
 * Query to fetch the content of a message.
 */
export const GET_MESSAGE = gql `
  ${MESSAGE_CONTENT}

  query GetMessage($id: ID!) {
    node(id: $id) {
      ...MessageContent
    }
  }
`;
