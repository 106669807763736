import React, { useState, useEffect } from 'react';
import { Alert, Button, Modal, Typography, Tooltip, List, message, Spin, } from 'antd';
import dayjs from 'dayjs';
import { CarOutlined, MobileOutlined, ExclamationCircleOutlined, CheckCircleOutlined, } from '@ant-design/icons';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { useIsAdmin } from '../state/hooks';
import { SafeLink as Link } from '../util/safeNavigate';
import { findRideForCourtDate } from '../Transit/find';
import { styleForStatus } from '../Transit/style';
import { Role, DeviceType } from '../net/types/graphql';
import { useUpdateFsmState } from '../net/query/UpdateFsmState.gql';
import { useClientInfoCtx } from '../ClientInfoProvider';
import { formatPhoneNumber } from '../util/phoneNumber';
import { StateEditor } from '../StateEditor';
/**
 * Width of graph visualization.
 */
const GRAPH_MODAL_WIDTH = 600;
/**
 * Wrapper to load Graph lazily.
 *
 * Since d3 is not used elsewhere in the app and this will probably not be a
 * frequently used tool, it is split into a separate bundle and loaded only
 * when needed.
 */
export const GraphWrapper = (props) => {
    const Graph = React.lazy(() => import('./Graph'));
    return React.createElement(Graph, { ...props });
};
/**
 * Get a formatted phone number for the client.
 */
const getPhoneNumber = (clientInfo) => {
    const { devices } = clientInfo;
    const phone = devices?.find((d) => d.type === DeviceType.Mobile);
    if (!phone?.address) {
        return (React.createElement(Typography.Text, { type: "danger" }, "No phone number available."));
    }
    const pn = formatPhoneNumber(phone.address, 'US');
    if (!pn.valid) {
        return (React.createElement(Typography.Text, { type: "danger" },
            React.createElement(Tooltip, { title: `This phone number is invalid: ${pn.error}` }, phone.address)));
    }
    return React.createElement(Typography.Text, { strong: true }, pn.text);
};
/**
 * Test whether a string is actualy a hyperlink.
 *
 * Link might be a deep link to an arbitrary app like `nudge://client/1234`.
 */
const isRealLink = (s) => {
    return /^[a-z][a-z0-9_]*:\/\/[^\s]+$/i.test(s);
};
/**
 * View for information about the recipient of the chat.
 */
export const ChatInfo = () => {
    const isAdmin = useIsAdmin();
    const { loading, error, clientInfo } = useClientInfoCtx();
    if (loading) {
        return null;
    }
    if (error || !clientInfo) {
        return React.createElement(React.Fragment, null,
            "Error loading client info: ",
            error?.message);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { title: `${clientInfo.fullName}, ${clientInfo.gender || 'unknown gender'}` },
            React.createElement(Typography.Text, null, clientInfo.fullName),
            clientInfo.gender ? (React.createElement(React.Fragment, null,
                '\u00A0',
                React.createElement(Typography.Text, { type: "secondary" },
                    "(",
                    clientInfo.gender,
                    ")"))) : null),
        React.createElement("div", null, getPhoneNumber(clientInfo)),
        React.createElement("div", { title: clientInfo.language || 'No language preference' },
            React.createElement(Typography.Text, null, clientInfo.language || 'No language preference')),
        isAdmin ? (React.createElement("div", { className: "transit-button" },
            React.createElement(Link, { to: `/chat/${clientInfo.id}/rides` },
                React.createElement(Button, { shape: "circle" },
                    React.createElement(CarOutlined, { style: {
                            color: 'var(--nudge-info-color)',
                        } }))))) : null));
};
/**
 * Get hash of info using the "external app link" from the org adapter.
 *
 * The link might be a real link to some CMS (e.g., Salesforce), or it might
 * be a custom string. If the custom string looks like it contains a label,
 * then format it accordingly.
 */
const getIDInfo = (appLink) => {
    if (isRealLink(appLink)) {
        return {
            label: 'Profile',
            value: (React.createElement(Typography.Link, { href: appLink, target: "_blank" }, "View client profile")),
            help: "Link to client's information in your organization's CMS",
        };
    }
    // If the link includes its own label, parse it out.
    if (/^[\w\d\s-]+:\s*.*$/.test(appLink)) {
        const [label, value] = appLink.split(':', 2);
        return {
            label,
            value: value.trim(),
            help: "Client's ID in your organization's CMS",
        };
    }
    return {
        label: 'ID',
        value: appLink,
        help: "Client's ID in your organization's CMS",
    };
};
/**
 * Create a link to Google Maps for the given address.
 */
export const googleMapsLink = (s) => {
    if (!s) {
        return null;
    }
    const link = `https://maps.google.com/?q=${encodeURIComponent(s)}`;
    return (React.createElement(Button, { type: "link", style: { whiteSpace: 'normal' }, href: link, target: "_blank" }, s));
};
/**
 * Create a mailto link.
 */
export const emailLink = (s) => {
    if (!s) {
        return null;
    }
    const link = `mailto:${s}`;
    return (React.createElement(Button, { type: "link", href: link }, s));
};
/**
 * Info about the client that there's not room for in the main screen.
 */
export const ChatMoreInfo = () => {
    const apolloClient = useApolloClient();
    const isAdmin = useIsAdmin();
    const { loading, error, clientInfo, featureAssignments, fsm } = useClientInfoCtx();
    const fsmStates = fsm?.meta?.nestedStates || [];
    const [initialFsmState, setInitialFsmState] = useState('');
    const [updateFsmState, { error: errorUpdateFsmState, loading: loadingUpdateFsmState },] = useUpdateFsmState();
    useEffect(() => {
        const s = clientInfo?.fsmState || '';
        setInitialFsmState(s);
    }, [clientInfo?.fsmState]);
    if (loading) {
        return null;
    }
    if (error || !clientInfo) {
        return React.createElement(React.Fragment, null,
            "Error loading client info: ",
            error?.message);
    }
    const twilioPhoneNumber = formatPhoneNumber(clientInfo.twilioNumber, 'US');
    return (React.createElement("div", { className: "nudge-more-info-inner" },
        React.createElement(List, { header: React.createElement(React.Fragment, null,
                React.createElement(Typography.Title, { level: 5 }, "More contact info"),
                clientInfo.isActive ? null : (React.createElement(Alert, { type: "error", showIcon: true, message: "We failed to fetch data about this person. You may still try to communicate with them if needed, but be aware that the information you see here may be outdated." }))), dataSource: [
                getIDInfo(clientInfo.externalAppLink || ''),
                {
                    label: 'Outbound Number',
                    value: twilioPhoneNumber.valid ? (twilioPhoneNumber.text) : (React.createElement(Typography.Text, { type: "danger" },
                        React.createElement(Tooltip, { title: `Invalid phone number: ${twilioPhoneNumber.error}` }, twilioPhoneNumber.text))),
                    help: 'The messages you are sending will come from this phone number.',
                },
                {
                    label: 'E-mail',
                    value: emailLink(clientInfo.email),
                    help: 'This is the e-mail address on file for this client.',
                },
                {
                    label: 'Address',
                    value: googleMapsLink(clientInfo.address),
                    help: 'This is the physical address on file for this client.',
                },
            ], renderItem: (item) => (React.createElement(List.Item, null,
                React.createElement(Tooltip, { title: item.help },
                    React.createElement(Typography.Text, { type: "secondary", style: { wordBreak: 'unset' } }, item.label)),
                React.createElement(Typography.Text, { style: { textAlign: 'right' } }, item.value || '--'))) }),
        React.createElement(List, { header: React.createElement(Typography.Title, { level: 5 }, "Assigned attorneys"), dataSource: clientInfo.assignedStaff.slice(), renderItem: (item) => {
                const nudgeUser = item.roles.includes(Role.Staff);
                const color = nudgeUser
                    ? 'var(--nudge-success-color)'
                    : 'var(--nudge-danger-color)';
                const hoverText = nudgeUser
                    ? `${item.fullName} uses Nudge!`
                    : `${item.fullName} does not currently have access to Nudge.`;
                return (React.createElement(List.Item, { title: hoverText },
                    React.createElement(List.Item.Meta, { avatar: React.createElement(MobileOutlined, { style: {
                                color,
                                visibility: nudgeUser ? 'visible' : 'hidden',
                            } }), title: React.createElement(Typography.Text, { style: { color } }, item.fullName) })));
            } }),
        React.createElement(List, { header: React.createElement(Typography.Title, { level: 5 }, "Recent / Upcoming court dates"), dataSource: clientInfo.courtDates.slice(), renderItem: (item) => {
                const ride1 = findRideForCourtDate(item, clientInfo.rides, 'to');
                const ride1Style = styleForStatus(ride1?.status);
                const ride2 = findRideForCourtDate(item, clientInfo.rides, 'from');
                const ride2Style = styleForStatus(ride2?.status);
                return (React.createElement(List.Item, null,
                    React.createElement(List.Item.Meta, { avatar: React.createElement(React.Fragment, null,
                            React.createElement("div", null,
                                React.createElement(Button, { shape: "circle" }, item.confirmedDate ? (React.createElement(Tooltip, { title: `Client confirmed they will attend on ${dayjs(item.confirmedDate).format('LLLL')}.` },
                                    React.createElement(CheckCircleOutlined, { style: { color: 'var(--nudge-success-color)' } }))) : (React.createElement(Tooltip, { title: "Client has not yet confirmed attendance." },
                                    React.createElement(ExclamationCircleOutlined, { style: { color: 'var(--nudge-danger-color)' } }),
                                    ' '))))), title: dayjs(item.courtDate).format('llll'), description: React.createElement("div", null,
                            isAdmin ? (React.createElement(React.Fragment, null,
                                React.createElement("div", null,
                                    React.createElement(Tooltip, { title: ride1Style.title },
                                        React.createElement(Typography.Text, null, "Ride to court:"),
                                        ' ',
                                        React.createElement(Typography.Text, { style: { color: ride1Style.color } }, ride1Style.text))),
                                React.createElement("div", null,
                                    React.createElement(Tooltip, { title: ride1Style.title },
                                        React.createElement(Typography.Text, null, "Ride from court:"),
                                        ' ',
                                        React.createElement(Typography.Text, { style: { color: ride2Style.color } }, ride2Style.text))))) : null,
                            React.createElement("div", null,
                                React.createElement(Tooltip, { title: `Top charge: ${item.topCharge}` },
                                    React.createElement(Typography.Text, null,
                                        "Case Number: ",
                                        item.caseNumber))),
                            React.createElement("div", null,
                                React.createElement(Typography.Text, { type: "secondary" }, item.courtLocation)),
                            item.courtRoom ? (React.createElement("div", null,
                                React.createElement(Typography.Text, { type: "secondary" },
                                    "Room: ",
                                    item.courtRoom))) : null,
                            item.online ? (React.createElement("div", null,
                                React.createElement(Typography.Text, { type: "secondary" }, "Online"))) : null,
                            item.notes ? (React.createElement("div", null,
                                React.createElement(Typography.Text, { mark: true }, item.notes))) : null) })));
            } }),
        isAdmin ? (React.createElement(List, { header: React.createElement(Typography.Title, { level: 5 }, "\uD83D\uDC1B Debug"), dataSource: [
                {
                    label: 'FSM State',
                    help: 'Inspect the state of the finite state machine.',
                    value: (React.createElement(StateEditor, { states: fsmStates, current: initialFsmState, error: errorUpdateFsmState, loading: loadingUpdateFsmState, onUpdate: (value) => {
                            if (value !== initialFsmState) {
                                Modal.confirm({
                                    title: 'Are you sure?',
                                    content: (React.createElement(React.Fragment, null,
                                        React.createElement("p", null,
                                            "This will update the FSM state from",
                                            ' ',
                                            React.createElement("code", null, initialFsmState),
                                            " to",
                                            ' ',
                                            React.createElement("code", null, value),
                                            "."),
                                        React.createElement("p", null,
                                            React.createElement("strong", null, "Using the wrong state may interfere with automated messaging!")))),
                                    onOk() {
                                        if (clientInfo.__typename !== 'ClientAccount') {
                                            return;
                                        }
                                        updateFsmState({
                                            update: () => {
                                                setInitialFsmState(value);
                                                message.success(`Updated FSM state to "${value}!"`);
                                            },
                                            variables: {
                                                accountId: clientInfo.id,
                                                newState: value,
                                            },
                                        });
                                    },
                                    onCancel() {
                                        message.warning(`Keeping FSM state "${initialFsmState}."`);
                                    },
                                });
                            }
                        } })),
                },
                {
                    label: 'FSM Graph',
                    help: 'Visualize the graph',
                    value: (React.createElement(Button, { onClick: () => Modal.info({
                            title: 'Client FSM State',
                            onOk() {
                                /* no-op */
                            },
                            width: GRAPH_MODAL_WIDTH,
                            content: clientInfo.id ? (React.createElement(ApolloProvider, { client: apolloClient },
                                React.createElement(React.Suspense, { fallback: React.createElement("div", { className: "-centered" },
                                        React.createElement(Spin, null)) },
                                    React.createElement(GraphWrapper, { accountId: clientInfo.id, width: GRAPH_MODAL_WIDTH })))) : ('Missing client ID!'),
                        }) }, "Show graph")),
                },
                {
                    label: 'Features',
                    help: 'Feature assignments',
                    block: true,
                    value: (React.createElement(List, { itemLayout: "horizontal", dataSource: featureAssignments?.slice() || [], renderItem: (item) => (React.createElement(List.Item, null,
                            React.createElement(List.Item.Meta, { avatar: item.featureName, description: React.createElement("span", { title: `Value: ${item.value}` }, item.variant) }))) })),
                },
            ], renderItem: (item) => (React.createElement(List.Item, null,
                React.createElement(Tooltip, { title: item.help },
                    React.createElement(Typography.Text, { type: "secondary" }, item.label)),
                item.block ? (React.createElement("div", { style: { maxWidth: 175, flexBasis: 150, flexGrow: 1 } }, item.value)) : (React.createElement(Typography.Text, { style: { textAlign: 'right' } }, item.value)))) })) : null));
};
