import { hasMoreMessages } from '../../query/Chat.gql';
import { mergeMessageLists } from '../message';
import * as log from '../../logger';
/**
 * Store message history by client.
 *
 * The query supports pagination, result pages are joined and deduplicated in
 * the cache.
 */
export const getMessageHistory = {
    keyArgs: ['clientAccountID'],
    merge: (existing = [], incoming = [], opts) => {
        // Mark history as exhausted if the query returns fewer than
        // we requested.
        const id = opts.args?.clientAccountID;
        if (!id) {
            log.warn('Missing client account ID on message query');
        }
        else if (incoming.length < (opts.args?.limit || 0)) {
            const hasMore = hasMoreMessages();
            hasMore.set(id, false);
            hasMoreMessages(hasMore);
        }
        return mergeMessageLists(existing.slice(), incoming.slice(), opts);
    },
};
