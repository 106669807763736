import React from 'react';
import { Menu, Layout } from 'antd';
import { RobotOutlined, AuditOutlined } from '@ant-design/icons';
import { Outlet, useMatch } from 'react-router-dom';
import { useIsAdmin } from '../state/hooks';
import { useSafeNavigate as useNavigate } from '../util/safeNavigate';
/**
 * Layout wrapper for the audit routes.
 */
export const AuditWindow = () => {
    const isAdmin = useIsAdmin();
    const match = useMatch('/audit/:key');
    const navigate = useNavigate();
    const items = [
        {
            title: 'Follow-up on your recent chat threads.',
            onClick: () => navigate('/audit/follow-up'),
            icon: React.createElement(AuditOutlined, null),
            key: 'follow-up',
            label: 'Follow Up',
        },
    ];
    if (isAdmin) {
        items.push({
            title: 'Inspect responses that we could not respond to correctly.',
            onClick: () => navigate('/audit/replies'),
            icon: React.createElement(RobotOutlined, null),
            key: 'replies',
            label: 'Responses',
        });
    }
    return (React.createElement(Layout, null,
        React.createElement(Layout.Sider, { breakpoint: "sm", theme: "light", collapsible: true },
            React.createElement(Menu, { selectedKeys: [match?.params.key || ''], items: items })),
        React.createElement(Layout.Content, { className: "nudge-body" },
            React.createElement(Outlet, null))));
};
