import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { message, Col, Row, Alert, Input, Button } from 'antd';
import { GoogleMap, DirectionsRenderer } from '@react-google-maps/api';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useApolloClient, } from '@apollo/client';
import { GoogleMapsApiProvider } from '../Transit/GoogleMapsApi';
import { useSafeNavigate as useNavigate } from '../util/safeNavigate';
import { useContainerSize } from '../util/useContainerSize';
import { useClientInfoCtx } from '../ClientInfoProvider';
import { useGoogleMapsApi } from './GoogleMapsApi';
import { DatePicker } from '../DatePicker';
import { geocode } from '../net/query/Geocode.gql';
import { useRequestRide } from '../net/query/RequestRide.gql';
/**
 * Check if waypoint represents the (0,0) lat/lng coordinate.
 */
const isZero = (a) => {
    return a.lat === 0 && a.lng === 0;
};
/**
 * Find center between two points.
 */
const findCenter = (a, b) => {
    if (isZero(a)) {
        return [b.lat, b.lng];
    }
    else if (isZero(b)) {
        return [a.lat, a.lng];
    }
    return [(a.lat + b.lat) / 2, (a.lng + b.lng) / 2];
};
/**
 * Indicator of whether ride timing makes sense.
 */
var RideTiming;
(function (RideTiming) {
    RideTiming[RideTiming["TooEarly"] = 0] = "TooEarly";
    RideTiming[RideTiming["Safe"] = 1] = "Safe";
    RideTiming[RideTiming["Close"] = 2] = "Close";
    RideTiming[RideTiming["TooLate"] = 3] = "TooLate";
})(RideTiming || (RideTiming = {}));
/**
 * Write out a human-readable explanation of the analysis.
 */
const explainDirectionAnalysis = (verdict) => {
    switch (verdict) {
        case RideTiming.TooEarly:
            return 'The client will arrive very early with this pickup time.';
        case RideTiming.Safe:
            return 'The pickup time seems good!';
        case RideTiming.Close:
            return 'The pickup time is cutting it close. You might want to schedule it a bit earlier.';
        case RideTiming.TooLate:
            return 'The client will be late. Schedule the pickup time earlier.';
        default:
            return 'Not available';
    }
};
/**
 * Figure out whether current ride request works, given directions and the
 * target court date.
 */
const analyzeDirections = ({ directions, rideRequest, target, }) => {
    const route = directions?.routes[0] || { legs: [] };
    const leg = route.legs[0];
    const na = {
        value: 0,
        text: 'Not available',
    };
    const durationInTraffic = leg?.duration_in_traffic || na;
    const duration = leg?.duration || na;
    const hasValidGuess = !!(leg?.duration_in_traffic || leg?.duration);
    const padding = 15 * 60; // seconds. This is defined in the app config.
    const bestGuess = durationInTraffic.value || duration.value || 0;
    const estArrival = dayjs(rideRequest.pickupAt).add(bestGuess, 'seconds');
    const targetArrival = dayjs(target);
    let lateness = RideTiming.Safe;
    // Definitely too late
    if (estArrival.isAfter(targetArrival)) {
        lateness = RideTiming.TooLate;
    }
    else if (estArrival.add(padding, 'seconds').isAfter(targetArrival)) {
        lateness = RideTiming.Close;
    }
    else if (estArrival.add(1, 'hour').isBefore(targetArrival)) {
        lateness = RideTiming.TooEarly;
    }
    return {
        arrivalTime: hasValidGuess ? estArrival : null,
        travelTimeSeconds: hasValidGuess ? bestGuess : null,
        verdict: hasValidGuess ? lateness : null,
        durationInTraffic,
        duration,
    };
};
/**
 * Schedule a new ride.
 */
export const NewRide = ({ client, courtDate, onSuccess, onCancel, direction, }) => {
    const apolloClient = useApolloClient();
    const gmaps = useGoogleMapsApi();
    const container = useContainerSize();
    const [map, setMap] = useState(null);
    const [requestRide, { loading, error }] = useRequestRide();
    const [directions, setDirections] = useState(undefined);
    const [rideRequest, setRideRequest] = useState({
        for: client.id,
        from: {
            lat: 0,
            lng: 0,
            address: direction === 'to' ? client.address : courtDate.courtLocation,
        },
        to: {
            lat: 0,
            lng: 0,
            address: direction === 'to' ? courtDate.courtLocation : client.address,
        },
        pickupAt: dayjs(courtDate.courtDate).toDate(),
        caseNumber: courtDate.caseNumber,
    });
    const now = new Date();
    const validDate = rideRequest.pickupAt > now;
    const anyError = error || (!validDate ? new Error("Can't schedule ride in the past") : null);
    // Refresh coordinates for geocoding
    const refreshGeocode = async () => {
        // Refresh both FROM and TO coords.
        // NOTE: this makes requests serially; in general only one coord will be
        // refreshed at a time (since only one will change at a time) while the
        // other is fetched from the cache.
        const points = ['from', 'to'];
        let baseRequest = rideRequest;
        for (const point of points) {
            const waypoint = baseRequest[point];
            if (waypoint.lat === 0 && waypoint.lng === 0) {
                const gc = await geocode(apolloClient, waypoint.address);
                if (!gc.data?.geocode || gc.loading || gc.error) {
                    // TODO report
                    message.error(`Error geocoding address: ${gc.error?.message}`);
                }
                else {
                    baseRequest = {
                        ...baseRequest,
                        [point]: { ...gc.data.geocode },
                    };
                }
            }
        }
        // In case we updated both FROM and TO simultaneously, wait til end to set
        // the new state.
        setRideRequest(baseRequest);
    };
    // Refresh directions when lat/lng from origin/dest change.
    const refreshDirections = async () => {
        if (isZero(rideRequest.from) || isZero(rideRequest.to)) {
            setDirections(undefined);
            return;
        }
        map?.fitBounds(new gmaps.LatLngBounds(new gmaps.LatLng(rideRequest.from.lat, rideRequest.from.lng), new gmaps.LatLng(rideRequest.to.lat, rideRequest.to.lng)));
        const ds = new gmaps.DirectionsService();
        const result = await ds.route({
            origin: new gmaps.LatLng(rideRequest.from.lat, rideRequest.from.lng),
            destination: new gmaps.LatLng(rideRequest.to.lat, rideRequest.to.lng),
            travelMode: gmaps.TravelMode.DRIVING,
            drivingOptions: {
                trafficModel: gmaps.TrafficModel.PESSIMISTIC,
                departureTime: rideRequest.pickupAt,
            },
        });
        setDirections(result);
    };
    useEffect(() => {
        refreshDirections();
    }, [
        rideRequest.from.lat,
        rideRequest.from.lng,
        rideRequest.to.lat,
        rideRequest.to.lng,
        rideRequest.pickupAt,
    ]);
    // Refresh geocode on load
    useEffect(() => {
        refreshGeocode().catch((e) => message.error(`Failed to geocode: ${e}`));
    }, []);
    const dirAnalysis = analyzeDirections({
        rideRequest,
        target: courtDate.courtDate,
        directions,
    });
    return (React.createElement("div", { id: "nudge-manage-ride", ref: container.ref },
        React.createElement(GoogleMap, { onLoad: setMap, onUnmount: () => setMap(null), center: new gmaps.LatLng(...findCenter(rideRequest.from, rideRequest.to)), options: { disableDefaultUI: true }, mapContainerStyle: { width: container.width, height: container.height }, zoom: 7 },
            React.createElement(DirectionsRenderer, { options: { directions } })),
        React.createElement("div", { className: "-overlay" },
            React.createElement(Row, null,
                React.createElement(Input, { type: "text", addonBefore: "From", disabled: loading, value: rideRequest.from.address, onChange: (e) => setRideRequest({
                        ...rideRequest,
                        ...{ from: { lat: 0, lng: 0, address: e.target.value } },
                    }), onBlur: () => refreshGeocode() })),
            React.createElement(Row, null,
                React.createElement(Input, { type: "text", addonBefore: "To", disabled: loading, value: rideRequest.to.address, onChange: (e) => setRideRequest({
                        ...rideRequest,
                        ...{ to: { lat: 0, lng: 0, address: e.target.value } },
                    }) })),
            React.createElement(Row, null,
                React.createElement(Col, { span: 12 }, "Pick up time"),
                React.createElement(Col, { span: 12 },
                    React.createElement(DatePicker, { showTime: true, disabled: loading, value: dayjs(rideRequest.pickupAt), onChange: (e) => setRideRequest({
                            ...rideRequest,
                            pickupAt: e?.toDate() || new Date(),
                        }) }))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 12 }, "Court date"),
                React.createElement(Col, { span: 12 }, dayjs(courtDate.courtDate).format('LLL'))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 12 }, "Arrival time"),
                React.createElement(Col, { span: 12 }, dirAnalysis.arrivalTime?.format('LLL') || 'Not available')),
            React.createElement(Row, null,
                React.createElement(Col, { span: 12 }, "Travel time"),
                React.createElement(Col, { span: 12 }, dirAnalysis.durationInTraffic.value
                    ? dirAnalysis.durationInTraffic.text
                    : dirAnalysis.duration.text)),
            direction === 'to' ? (React.createElement(Row, null, explainDirectionAnalysis(dirAnalysis.verdict))) : null,
            React.createElement(Row, null, anyError ? React.createElement(Alert, { type: "error", message: anyError.message }) : null),
            React.createElement(Row, null,
                React.createElement(Button, { type: "primary", onClick: () => requestRide({
                        variables: rideRequest,
                        onCompleted: (result) => {
                            if (result?.scheduleRide?.id && onSuccess) {
                                return onSuccess(result);
                            }
                        },
                    }), loading: loading, disabled: !validDate }, "Create"),
                React.createElement(Button, { type: "link", disabled: loading, onClick: onCancel }, "Cancel")))));
};
/**
 * UI for managing a ride for a client to a specific court date.
 */
export const RequestRide = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { loading, error, clientInfo } = useClientInfoCtx();
    if (loading) {
        return null;
    }
    if (error || !clientInfo) {
        return React.createElement(React.Fragment, null,
            "Error loading client info: ",
            error?.message);
    }
    if (!location.state) {
        return React.createElement(React.Fragment, null, "cannot schedule a new ride");
    }
    const direction = (searchParams.get('d') || 'to').toLowerCase();
    return (React.createElement(GoogleMapsApiProvider, null,
        React.createElement(NewRide, { client: clientInfo, direction: direction === 'to' ? 'to' : 'from', courtDate: JSON.parse(location.state), onCancel: () => {
                navigate(`/chat/${clientInfo.id}/rides`);
            }, onSuccess: () => {
                message.success(`Scheduled a ride for ${clientInfo.fullName}!`);
                navigate(`/chat/${clientInfo.id}/rides`);
            } })));
};
