import { getId } from '../id';
import { getMessageThreadId, insertMessage } from '../message';
import * as log from '../../logger';
/**
 * Merge Message objects from the `node` query into the cache.
 */
export const mergeMessageNode = (existing, incoming, opts) => {
    const merged = existing ? opts.mergeObjects(existing, incoming) : incoming;
    // Get the source and destination of the message.
    const source = opts.readField('source', merged);
    // Validate that source is known. The source of every message that goes
    // through Nudge should be known; this is not true for the destination,
    // which can be ambiguous.
    if (!source?.account) {
        log.error(`Failed to read source account for message: ${incoming.id}`);
        return merged;
    }
    const fromAccount = source.account;
    const dest = opts.readField('destination', merged);
    if (!dest?.account) {
        // This is expected in some cases, e.g. if the client is not assigned to
        // any particular staff, so just continue.
    }
    const toAccount = dest?.account;
    // Inspect types of source / destination
    const fromType = opts.readField('__typename', fromAccount) || 'Unknown';
    const toType = opts.readField('__typename', toAccount) || 'Unknown';
    // Check message direction
    const inbound = fromType !== 'StaffAccount';
    // Validate that destination exists for outbound messages.
    if (!inbound && !toAccount) {
        log.error('Failed to read destination account for outbound message!');
        return merged;
    }
    // Get the ID of the client. This is where the message thread is cached.
    const msgThreadId = getId(opts, inbound ? fromAccount : toAccount);
    const msgThreadType = inbound ? fromType : toType;
    // Update the message thread to include the incoming one.
    opts.cache.modify({
        id: 'ROOT_QUERY',
        fields: {
            [getMessageThreadId(msgThreadId)](history = []) {
                return insertMessage(history.slice(), { ...incoming }, opts);
            },
        },
    });
    // Update the latest message in the thread to be in the
    // incoming message.
    opts.cache.modify({
        id: `${msgThreadType}:${msgThreadId}`,
        fields: {
            lastMessage() {
                return opts.toReference(incoming);
            },
        },
    });
    return merged;
};
/**
 * Field policy to handle caching on the `node` query.
 *
 * If I were to rewrite the API, I'd get rid of this `node` query entirely,
 * because the caching logic is too annoying to write (compared to having more
 * specific fields built into the API that we can write individual policies
 * for, avoiding all the messy typechecking in here).
 */
export const node = {
    merge(existing, incoming, opts) {
        if (!incoming) {
            return existing;
        }
        // Merge incoming msgs into the source account's message history
        const typename = opts.readField('__typename', incoming);
        switch (typename) {
            case 'Message':
                return mergeMessageNode(existing, incoming, opts);
            default:
                // For other cases, do a normal merge.
                return existing ? opts.mergeObjects(existing, incoming) : incoming;
        }
    },
};
