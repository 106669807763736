import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DeviceType } from '../net/types/graphql';
import { REGISTER_DEVICE } from '../net/query/RegisterDevice.gql';
import { UNREGISTER_DEVICE } from '../net/query/UnregisterDevice.gql';
import { FCM } from '../net/query/Fcm.gql';
import { handleMessage } from '../net/push/handleMessage';
import { NudgeServiceWorker } from '../bg/NudgeServiceWorker';
/**
 * Interface to the push handling service worker.
 */
export const sw = new NudgeServiceWorker();
/**
 * Unregister a device from push notifications.
 */
export const unregisterPushDevice = createAsyncThunk('push/unregister', async ({ client, deviceId }) => {
    await sw.detach();
    // Unregister the push device on the server.
    const r = await client.mutate({
        mutation: UNREGISTER_DEVICE,
        variables: {
            deviceId,
        },
    });
    if (r.errors) {
        throw new Error(r.errors.join(', '));
    }
});
/**
 * Get push token for the device and register it with the server.
 */
export const registerPushDevice = createAsyncThunk('push/register', async ({ client, userId, deviceId }) => {
    // Fetch the FCM config from the API
    const fcmConfig = await client.query({ query: FCM });
    if (fcmConfig.error) {
        throw fcmConfig.error;
    }
    if (fcmConfig.errors) {
        throw new Error(fcmConfig.errors.join(','));
    }
    if (!deviceId) {
        throw new Error('Device ID is unavailable');
    }
    // Get a valid push token using Firebase.
    const token = await sw.attach(fcmConfig.data.fcmConfig, (msg) => {
        handleMessage(client, userId, {
            data: msg.payload,
        });
    });
    // Register the push token with the API so it will send this user's pushes
    // to our service worker.
    const r = await client.mutate({
        mutation: REGISTER_DEVICE,
        variables: {
            deviceId,
            deviceType: DeviceType.Web,
            ownerId: userId,
            token,
        },
    });
    if (r.errors) {
        throw new Error(r.errors.join(', '));
    }
    return {
        token,
    };
});
export const pushSlice = createSlice({
    name: 'push',
    initialState: {
        token: '',
        registering: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Register
        builder.addCase(registerPushDevice.pending, (state) => {
            state.registering = true;
            state.token = '';
            state.error = null;
        });
        builder.addCase(registerPushDevice.fulfilled, (state, action) => {
            state.registering = false;
            state.token = action.payload.token;
            state.error = null;
        });
        builder.addCase(registerPushDevice.rejected, (state, action) => {
            state.registering = false;
            state.token = '';
            state.error = action.error?.message || 'unknown error';
        });
        // Unregister
        builder.addCase(unregisterPushDevice.pending, (state) => {
            state.registering = true;
            state.token = '';
            state.error = null;
        });
        builder.addCase(unregisterPushDevice.fulfilled, (state) => {
            state.registering = false;
            state.token = '';
            state.error = null;
        });
        builder.addCase(unregisterPushDevice.rejected, (state, action) => {
            state.registering = false;
            state.token = '';
            state.error = action.error?.message || 'unknown error';
        });
    },
});
export const { reducer } = pushSlice;
