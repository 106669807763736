import { gql, useQuery } from '@apollo/client';
/**
 * Query to fetch conversations for following-up on.
 */
export const FSM_STATES = gql `
  query FsmStates {
    fsm {
      meta {
        states
      }
    }
  }
`;
/**
 * Hook to run the FSM States query.
 */
export const useFsmStates = (opts) => useQuery(FSM_STATES, opts);
