import React from 'react';
import { Typography, List, Alert, Skeleton } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs from 'dayjs';
import { useSafeNavigate as useNavigate } from '../util/safeNavigate';
import * as log from '../util/log';
import { useAppSelector } from '../state/hooks';
import { useFollowUpThreads } from '../net/query/FollowUpThreads.gql';
/**
 * View for following up on recent chat threads.
 */
export const FollowUp = () => {
    const navigate = useNavigate();
    const { userId } = useAppSelector(({ auth }) => auth);
    const { data, loading, error, fetchMore, refetch } = useFollowUpThreads({
        errorPolicy: 'all',
        variables: {
            id: userId,
            offset: 0,
            limit: 30,
        },
    });
    if (!!data?.node && data.node.__typename !== 'StaffAccount') {
        throw new Error('Unexpected type in follow-up threads query.');
    }
    const node = data?.node;
    const threads = node?.followUpChats?.slice() || [];
    // Sort in reverse chronological order (most recent first).
    threads.sort((a, b) => (a.seq > b.seq ? -1 : 1));
    const nextBatchOffset = threads.length;
    return (React.createElement("div", { id: "nudge-follow-up" },
        React.createElement(PageHeader, { title: "Follow Up", backIcon: false }),
        error ? (React.createElement(Alert, { type: "error", message: error.message, showIcon: true })) : null,
        React.createElement("div", { id: "nudge-follow-up-thread-container" },
            React.createElement(InfiniteScroll, { scrollableTarget: "nudge-follow-up-thread-container", dataLength: threads.length, next: () => fetchMore({
                    variables: {
                        offset: nextBatchOffset,
                    },
                }), loader: React.createElement(Skeleton.Button, { shape: "round", className: "nudge-list-placeholder", active: true }), hasMore: node?.hasMoreFollowUps || false, pullDownToRefresh: true, refreshFunction: refetch },
                React.createElement(List, { className: "nudge-audit-content", loading: loading, dataSource: threads, renderItem: (item) => (React.createElement(List.Item, { className: `nudge-audit-message-item ${item.destination?.account?.id ? '-click' : ''}`, key: item.id, onClick: () => {
                            const clientId = item.destination?.account?.id;
                            if (!clientId) {
                                log.warn('Missing destination account ID unexpectedly!');
                                return;
                            }
                            navigate(`/chat/${clientId}?m=${item.id}`);
                        } },
                        React.createElement(List.Item.Meta, { avatar: React.createElement(Typography.Text, { type: "secondary", title: dayjs(item.sent).format('LLLL') }, dayjs(item.sent).fromNow()), title: item.destination?.account?.fullName || 'Unknown client', description: item.content }))) })))));
};
