import React from 'react';
import { Typography, message } from 'antd';
import { useAppSelector } from '../state/hooks';
import { useUnreadNotifs } from '../net/query/Notification.gql';
import * as log from '../util/log';
/**
 * Home screen to display when no chat is highlighted.
 */
export const ChatHome = () => {
    const userId = useAppSelector((state) => state.auth.userId);
    if (!userId) {
        log.error('Trying to show home screen to an unknown user!');
        message.error('Your app is in a bad state, logging you out ...');
        window.location.pathname = '/logout';
        return null;
    }
    const { data, loading, error } = useUnreadNotifs({
        variables: { accountId: userId },
    });
    let hasData = false;
    let unreads = 0;
    let plural = '';
    if (!loading && !error && data?.node?.__typename === 'StaffAccount') {
        unreads = data.node.unreadCount;
        plural = unreads === 1 ? '' : 's';
        hasData = true;
    }
    return (React.createElement("div", { id: "nudge-home-window" },
        React.createElement("div", { className: "nudge-home-info" },
            React.createElement(Typography.Title, { level: 3 }, "Hi, welcome to Nudge!")),
        hasData && (React.createElement("div", { className: "nudge-home-info -optional" },
            "You have ",
            unreads,
            " unread message",
            plural,
            "."))));
};
