import React, { useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';
import { useSafeNavigate as useNavigate } from './util/safeNavigate';
import { useAppDispatch, useAppSelector } from './state/hooks';
import { exchangeCodeForToken } from './state/auth';
import { LoadingContainer } from './LoadingContainer';
export const OAuth2Callback = () => {
    const authState = useAppSelector(({ auth }) => auth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const code = query.get('code');
    const state = query.get('state') || '';
    useEffect(() => {
        if (!code) {
            return;
        }
        dispatch(exchangeCodeForToken({
            codeVerifier: authState.codeVerifier,
            code,
            state,
        }));
    }, [code]);
    useEffect(() => {
        // Don't do anything if the current auth state describes a different code.
        if (code !== authState.code) {
            return;
        }
        // If the code exchange request is in flight, just wait.
        if (authState.refreshing) {
            return;
        }
        // Flash an error if the login didn't seem to succeed. Regardless, we will
        // redirect back to the login screen and double check.
        if (authState.error || !authState.loggedIn) {
            message.error(authState.error || 'An unknown error happened when trying to log in');
        }
        // Go back to the login screen. This will verify that the auth token
        // is actually valid and automatically redirect if so.
        navigate('/login?from=oauth2');
    }, [
        authState.refreshing,
        authState.error,
        authState.code,
        authState.codeVerifier,
        authState.loggedIn,
        code,
        state,
    ]);
    return (React.createElement(LoadingContainer, { error: authState.error ? new Error(authState.error) : undefined, data: authState.loggedIn, loading: authState.refreshing }));
};
