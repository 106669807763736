/**
 * The identity function
 */
export const identity = (x) => x;
/**
 * Insert an element into an array, keeping items sorted.
 *
 * Optionally supply a key function to sort by. The list can be maintained in
 * reverse order by setting the `reverse` argument.
 *
 * Insertion behavior is not well-defined if input list is not already sorted.
 */
export const sortedInsert = (A, x, key = null, reverse = false, merge = null) => {
    // Simple case: nothing in the list
    if (!A.length) {
        A.push(x);
        return;
    }
    // Binary search for correct position
    const val = key ? key(x) : x;
    let low = 0;
    let high = A.length;
    // Edge case where there is only one element in the list and it matches the
    // one we're considering now and we have a merge function.
    if (merge && high === 1) {
        const cmp = key ? key(A[0]) : A[0];
        if (val === cmp) {
            const merged = merge(A[0], x);
            // If the merge function returns undefined, the items will *not* be merged.
            if (merged !== undefined) {
                A[0] = merged;
                return;
            }
        }
    }
    // In every other case we need to do a binary search.
    while (high - low > 1) {
        // tslint:disable-next-line:no-bitwise
        const mid = low + ((high - low) >> 1);
        const cmp = key ? key(A[mid]) : A[mid];
        // NOTE: Typescript doesn't allow use of the XOR operator, but !== is equivalent.
        // The logic is: A < B XOR R
        if (val < cmp !== reverse) {
            high = mid;
        }
        else {
            if (merge && val === cmp) {
                // Merge the actual values. If this returns undefined, the items will
                // *not* be merged.
                const merged = merge(A[mid], x);
                if (merged !== undefined) {
                    A[mid] = merged;
                    return;
                }
            }
            // If there's no merge function, or if the values are not equal, keep
            // searching the insertion point.
            low = mid;
        }
    }
    const lowVal = key ? key(A[low]) : A[low];
    // Again, !== means XOR for reverse
    const index = val < lowVal !== reverse ? low : high;
    // Insert element at computed index
    A.splice(index, 0, x);
};
