import { gql, useQuery, makeVar } from '@apollo/client';
import { MESSAGE_CONTENT } from './fragments/MessageContent.gql';
/**
 * Query to fetch conversations for following-up on.
 */
export const FOLLOW_UP_THREADS = gql `
  ${MESSAGE_CONTENT}

  query FollowUpThreads($id: ID!, $limit: Int!, $offset: Int!) {
    node(id: $id) {
      id
      ... on StaffAccount {
        followUpChats(limit: $limit, offset: $offset) {
          ...MessageContent
        }
        hasMoreFollowUps @client
      }
    }
  }
`;
/**
 * Hook to run the follow-up chat threads query.
 */
export const useFollowUpThreads = (opts) => useQuery(FOLLOW_UP_THREADS, opts);
/**
 * Local schema extension for pagination of follow-up threads query.
 */
export const followUpsSchemaExtension = gql `
  extend type StaffAccount {
    hasMoreFollowUps: Boolean!
  }
`;
/**
 * Reactive variable to track local state about whether there are more threads
 * in the followUpChats query that can be accessed.
 */
export const hasMoreFollowUps = makeVar(true);
