import { gql, useMutation, } from '@apollo/client';
import { RIDE_DATA } from './fragments/RideData.gql';
import { updateOnScheduleRide } from '../cache/update/scheduleRide';
/**
 * Mutation for requesting a new ride.
 */
export const REQUEST_RIDE = gql `
  ${RIDE_DATA}
  mutation RequestRide(
    $for: ID!
    $from: RideRequestWaypoint!
    $to: RideRequestWaypoint!
    $pickupAt: Time
    $caseNumber: String!
  ) {
    scheduleRide(
      newRide: {
        clientId: $for
        origin: $from
        destination: $to
        pickupAt: $pickupAt
        caseNumber: $caseNumber
      }
    ) {
      client {
        id
      }
      ...RideData
    }
  }
`;
/**
 * Hook to request a new ride.
 */
export const useRequestRide = (opts) => useMutation(REQUEST_RIDE, {
    update: updateOnScheduleRide,
    ...opts,
});
