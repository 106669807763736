/**
 * Use the `status` field that comes from the `lyftDetails` field if possible.
 *
 * We expect the `lyftDetails` status field to be more up-to-date and fetched
 * more often than the outer `status` field, which may be cached at different
 * levels of the stack. Since they both represent the same information, they
 * should be kept in sync in the cache; so defer to the `lyftDetails` field as
 * the source of truth if they are both defined.
 */
export const status = {
    read(s, { readField }) {
        const details = readField('lyftDetails');
        return details?.status || s;
    },
};
